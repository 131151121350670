import React, { useState } from 'react';
import cn from 'classnames';
import { ContextMenuTrigger } from '@/dataroom/ui/common/ContextMenu';
import FolderIcon from '@/dataroom/ui/common/FilesystemIcon/FolderIcon';
import ToggleIcon from '@/dataroom/ui/common/ToggleIcon';
import treeNodeStyles from '@/dataroom/ui/components/Dataroom/components/FolderTree/Node/node.scss';
import styles from './node.scss';
import { contextMenuId } from '@/dataroom/ui/components/Dataroom/components/ContextMenu/constants';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import getFolderUrl from '@/dataroom/infrastructure/getFolderUrl';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { isFileIndexingEnabled } from '@/dataroom/domain/filesystem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { Area } from '@/dataroom/domain/vo/Area';

const NODE_OFFSET = 25;

export interface IProps {
  item: IFolderTree,
  isOpen: boolean,
  depth: number,
  isRoot: boolean,
  hasChildren: boolean,
  isLast: boolean,
  dataTest: string,
  dragAttributes?: object,
  dragListeners?: object,
  isOver?: boolean,
  isDragging?: boolean,
  isDropAvailable?: boolean,
}

const Node = React.forwardRef((props: IProps, ref) => {
  const {
    depth,
    isRoot,
    item,
    hasChildren,
    isLast,
    dataTest,
    dragAttributes = {},
    dragListeners = {},
    isOver = false,
    isDragging = false,
    isDropAvailable = false,
  } = props;

  const { dataroom } = useDataroomContext();
  const { currentFolder } = useCurrentFolderContext();

  const isSelected = currentFolder && currentFolder.id === item.id;
  const area = item.isStaging ? Area.Staging : Area.Primary;

  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

  const toggleOpen = () => { setIsOpen((prevState) => !prevState); };

  const nodeItemTitle = item.fileIndexFull && isFileIndexingEnabled(dataroom, area)
    ? `${ item.fileIndexFull } ${ item.name }`
    : item.name;

  return (
    <div
      className={ cn(styles.nodeItem, 'folder-tree-node', {
        [treeNodeStyles.isOpen]: isOpen,
        [styles.isSelected]: isSelected && !isDragging,
        [styles.isRoot]: isRoot,
        [styles.isNotRoot]: !isRoot,
        [styles.isNotLast]: !isLast,
        [styles.hoverDisabled]: isDragging,
        [styles.nodeItemDropHover]: isOver && isDropAvailable,
        'folder-tree-node-selected': isSelected && !isDragging,
      }) }
      style={ {
        paddingLeft: `${ NODE_OFFSET * depth }px`,
      } }
      data-test={ dataTest }
      // @ts-ignore
      ref={ ref }
      { ...dragAttributes }
      { ...dragListeners }

    >
      { hasChildren && !isRoot && (
        <ToggleIcon
          isOpen={ isOpen }
          onClick={ toggleOpen }
          className={ styles.nodeToggleIcon }
        />
      ) }
      <ContextMenuTrigger
        id={ contextMenuId }
        collect={ () => ({
          fileSystemItem: item,
        }) }
      >
        <NextNavLink to={ getFolderUrl(dataroom.name, item.id, item.isStaging) } className={ styles.folderLink }>
          <FolderIcon
            isOpen={ isOpen }
            className={ styles.nodeFolderIcon }
            isStaging={ item.isStaging }
            isRoot={ isRoot }
          />
          <div className={ cn(styles.nodeItemTitle) }>
            { nodeItemTitle }
          </div>
        </NextNavLink>
      </ContextMenuTrigger>
    </div>
  );
});

export default React.memo(Node);
