import React, { useState } from 'react';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import PortalWrp, { PortalId } from '@/ui/shared/components/Layout/PortalWrp';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { DocumentViewer as BaseDocumentViewer } from '@/ui/shared/modules/DocumentViewer';
import { canDownload } from '@/dataroom/domain/filesystemPermissions';
import { canUserBulkDownload } from '@/dataroom/domain/managePermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import styles from './documentViewer.scss';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import PreviewNotAvailable from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/PreviewNotAvailable';

const DocumentViewer = () => {
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const { requestDownload, download } = useDownloadContext();
  const {
    previewItem,
    defaultSearch,
    resetPreview,
    fileWatermark,
    isFetching,
    isError,
  } = useDocumentPreviewContext();

  const userCanBulkDownload = canUserBulkDownload(currentUser);

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const {
    dataroom: { watermarking, stagingWatermarking, watermarkingOpacity, stagingWatermarkingOpacity },
  } = useDataroomContext();
  const showWatermark = previewItem?.isStaging ? stagingWatermarking : watermarking;
  const opacity = previewItem?.isStaging ? stagingWatermarkingOpacity : watermarkingOpacity;

  const handleFinish = () => {
    setIsDownloadLoading(false);
  };

  const handleDownload = () => {
    setIsDownloadLoading(true);

    requestDownload({
      items: [previewItem],
      onFinish: handleFinish,
    }).then(download);
  };

  if (!previewItem && !isFetching && !isError) return null;

  return previewItem.previewable ? (
    <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
      <BaseDocumentViewer
        documentTitle={ previewItem?.name }
        defaultSearch={ defaultSearch }
        documentUrl={ dataroomUrl(tenant).getPreviewUrl(dataroom.name, previewItem?.id) }
        watermarkText={ showWatermark ? [fileWatermark, 'CONFIDENTIAL INFORMATION - DO NOT DISTRIBUTE'] : [] }
        className={ styles.documentPreviewer }
        close={ resetPreview }
        onDownload={ handleDownload }
        isDownloadDisabled={ !canDownload(previewItem, userCanBulkDownload, false, 1) }
        watermarkOpacity={ opacity }
        watermarkWidth={ 480 }
        isFetching={ isFetching }
        isDownloadLoading={ isDownloadLoading }
        isCustomError={ isError }
        defaultScale={ SpecialZoomLevel.ActualSize }
        minZoomLevel={ 50 }
        isBlurProtectionEnabled={ dataroom.blurEnabled }
        blurNotification="As a protection measure, your file is hidden when you are not interacting with the viewer. To view this file, move your mouse pointer back to this page."
      />
    </PortalWrp>
  ) : (
    <Modal
      title="Preview Not Available"
      isVisible
      className={ styles.previewNotAvailableModal }
      onCloseClicked={ resetPreview }
      dataTest="previewNotAvailableModal"
    >
      { (footerRefCallback) => (
        <PreviewNotAvailable
          item={ previewItem }
          closeModal={ resetPreview }
          footerRefCallback={ footerRefCallback }
        />
        ) }
    </Modal>
    );
};

export default DocumentViewer;
