import React, { useState, useMemo } from 'react';

import Modal from '@/dataroom/ui/common/Questions/Modal';
import QuestionForm from './QuestionForm';

import useCancelChangesModal from '@/dataroom/ui/common/Questions/CancelChangesModal/useCancelChangesModal';

import { IFile } from '@/dataroom/domain/questions/vo/File';
import { IUser } from '@/dataroom/domain/questions/vo/User';
import { ICategoryAction, ICreateAction, IFileAction } from '@/dataroom/domain/questions/vo/questionAction';
import { IQuestionLimits } from '@/dataroom/domain/questions/vo/QuestionLimits';

import styles from './questionModal.scss';

interface IProps {
  isVisible: boolean,
  closeModal: () => void,
  file: IFile,
  currentUser: IUser,
  create: ICreateAction,
  categories: ICategoryAction,
  files: IFileAction,
  onSuccessCallback: () => void,
  baseUrl: string,
  questionLimits: IQuestionLimits,
}

const QuestionModal = (
  {
    isVisible,
    closeModal,
    file,
    currentUser,
    create,
    files,
    categories,
    onSuccessCallback,
    baseUrl,
    questionLimits,
  }: IProps,
) => {
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(true);
  const [isFormDirty, setFormDirty] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    CancelChangesModal,
    showCanceledModal,
    hideCanceledModal,
    isVisible: isCancelChangesModalVisible,
  } = useCancelChangesModal();

  const handleCloseModal = (isSubmitted?: boolean) => {
    if (isFormDirty && !isSubmitted) {
      setIsQuestionModalVisible(false);
      showCanceledModal();
    } else {
      closeModal();
    }
  };

  const handleHideCanceledModal = () => {
    setIsQuestionModalVisible(true);
    hideCanceledModal();
  };

  const cancelChanges = () => {
    closeModal();
    handleHideCanceledModal();
  };

  const initialValues = useMemo(() => (
    { questions: [{
        priority: questionLimits.initialPriority,
        categoryId: '',
        questionText: '',
      }],
    }
  ), [questionLimits.initialPriority]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Modal
        isVisible={ isQuestionModalVisible }
        isLoading={ isLoading || !isInitialized }
        title="Ask a Question"
        className={ styles.modalWrp }
        onCloseClicked={ handleCloseModal }
        dataTest="questionModal"
      >
        { (footerRefCallback) => (
          <QuestionForm
            initialValues={ initialValues }
            closeModal={ handleCloseModal }
            onLoaded={ () => { setIsLoading(false); } }
            onInitialized={ () => { setInitialized(true); } }
            setFormDirty={ setFormDirty }
            file={ file }
            currentUser={ currentUser }
            create={ create }
            files={ files }
            categories={ categories }
            questionLimits={ questionLimits }
            onSuccessCallback={ onSuccessCallback }
            footerRefCallback={ footerRefCallback }
            isFormDirty={ isFormDirty }
            isFormLoading={ isLoading || !isInitialized }
            baseUrl={ baseUrl }
          />
          ) }
      </Modal>
      <CancelChangesModal
        isVisible={ isCancelChangesModalVisible }
        hideCanceledModal={ handleHideCanceledModal }
        cancelChanges={ cancelChanges }
      />
    </>
  );
};

export default QuestionModal;
