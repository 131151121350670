import React, { useMemo, useEffect } from 'react';
import { noop } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { Form, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusOnErrorsDecorator from 'final-form-focus';

import { IFinalFormProps, TDefaultFormValues } from './interfaces';
import { FINAL_FORM_API } from './constants';

const FinalForm = <
  FormValues extends TDefaultFormValues = TDefaultFormValues,
  InitialValues = Partial<FormValues>,
>(
  {
    name,
    dataTest,
    render,
    withFocusOnErrors = false,
    className = null,
    decorators = [],
    mutators = {},
    onLibraryLoaded = noop,
    ...otherProps
  }: IFinalFormProps<FormValues, InitialValues>,
) => {
  const focusOnErrors = useMemo(() => createFocusOnErrorsDecorator(), []);

  useEffect(
    () => {
      /* The first version of FinalForm had async imports, and 'onLibraryLoaded' was used to show Spinner while
      * the library is loading. Now this prop is marked as deprecated and all of its usages have to be removed.
      * This effect is a temporary solution until the usages are removed.
      *  */
      onLibraryLoaded();
    },
    [],
  );

  return (
    <Form<FormValues, InitialValues>
      { ...otherProps }
      onSubmit={ otherProps.onSubmit }
      decorators={ [...decorators, ...(withFocusOnErrors ? [focusOnErrors] : [])] }
      mutators={ { ...arrayMutators, ...mutators } }
      render={ (formRenderProps: FormRenderProps<FormValues, InitialValues>) => (
        <form
          name={ name }
          data-test={ dataTest }
          className={ className }
          onSubmit={ formRenderProps.handleSubmit }
        >
          { render(formRenderProps, FINAL_FORM_API) }
        </form>
      ) }
    />
  );
};

export default FinalForm;
