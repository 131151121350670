import React from 'react';
import HeaderBanner from '@/ui/shared/components/Layout/HeaderBanner';

import styles from './styles.scss';

interface IProps {
  children: React.ReactNode,
  isBannerEnabled?: boolean,
}

const LayoutWithBanner = ({ children, isBannerEnabled = true }: IProps) => (
  <div className={ styles.layoutContainer }>
    { isBannerEnabled && <HeaderBanner /> }
    <div className={ styles.layoutContentWrp }>
      { children }
    </div>
  </div>
);

export default LayoutWithBanner;
