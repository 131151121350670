import { useState } from 'react';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { filterSkippedItems, getNotificationPrefix, isFolder } from '@/dataroom/domain/filesystem';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import DataroomErrorHandler, { getFailedItems } from '@/dataroom/application/ErrorHandler';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';
import { IFilesystemConflictResolvingItem } from '@/dataroom/domain/vo/filesystem/FilesystemConflictResolvingItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFilesystemActionWithConflictPayload } from '@/dataroom/domain/vo/filesystem/FilesystemActionWithConflictPayload';
import { IDestination } from '@/dataroom/domain/vo/filesystem/Destination';
import { ResolvingType } from '@/dataroom/domain/vo/types/ResolvingType';
import { useDIContext } from '@/Framework/DI/DIContext';

interface IFetchPayload {
  filesystemItems: IFilesystemConflictResolvingItem[],
  destinationFolder: IFolderTree | IFilesystemListItem,
  permissionGroups: Partial<{ [key in PermissionGroup]: number[] }>,
  successItems: IFilesystemConflictResolvingItem[],
  successCallback: () => void,
  payload: {
    [key: string]: string | number,
  },
  fetchBySockets: boolean,
}

interface IActionWithConflictItem {
  id: number,
  destination?: IDestination,
  resolving?: ResolvingType,
}

const useActionWithConflict = (
  action: (payload: IFilesystemActionWithConflictPayload) => Promise<{
    successItems: IFilesystemConflictResolvingItem[],
    conflictItems: IFilesystemConflictResolvingItem[],
  }> | Promise<void>,
  prepareItems: (
    filesystemItems: IFilesystemConflictResolvingItem[],
    destinationFolder?: IFolderTree | IFilesystemListItem,
  ) => {
    folders: IActionWithConflictItem[],
    files: IActionWithConflictItem[],
  },
  successMessage: string,
  errorMessage: string,
) => {
  const { container } = useDIContext();
  const [destinationFolder, setDestinationFolder] = useState<IFolderTree>(null);
  const [successItems, setSuccessItems] = useState<IFilesystemConflictResolvingItem[]>([]);
  const [conflictItems, setConflictItems] = useState<IFilesystemConflictResolvingItem[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const { dataroom } = useDataroomContext();
  const { updateCollection } = useDataroomExplorerContext();

  const fetch = async ({
    filesystemItems,
    destinationFolder,
    permissionGroups = {},
    successItems = [],
    successCallback,
    payload = {},
    fetchBySockets,
  }: IFetchPayload) => {
    setIsFetching(true);

    const onFinish = (response) => {
      setSuccessItems(response.successItems);

      if (response.conflictItems?.length) {
        setConflictItems(response.conflictItems);
      } else {
        const prefix = getNotificationPrefix(filterSkippedItems(successItems.concat(response.successItems)));

        if (prefix) {
          NotificationManager.success(getMessage(successMessage, { prefix }));
        }

        successCallback && successCallback();

        updateCollection && updateCollection();
      }

      setIsFetching(false);
    };

    const onError = (error) => {
      const { failedFolderIds, failedFileIds } = getFailedItems(error);

      if (failedFolderIds || failedFileIds) {
        const failedFilesystemItems = filesystemItems.filter((item) => (
          isFolder(item) ? failedFolderIds.includes(item.id) : failedFileIds.includes(item.id)
        ));

        NotificationManager.error(getMessage(errorMessage, {
          prefix: getNotificationPrefix(filterSkippedItems(failedFilesystemItems)),
        }));
      } else {
        container.get(DataroomErrorHandler).handleError(error);
      }

      setIsFetching(false);
    };

    try {
      const { files, folders } = prepareItems(filesystemItems, destinationFolder);

      const customPermissionGroups = Object.keys(permissionGroups).reduce((
        acc: {
          id: number,
          permission: PermissionGroup,
        }[],
        permission: PermissionGroup,
      ) => {
        permissionGroups[permission].forEach((id) => acc.push({ id, permission }));
        return acc;
      }, []);

      const response = await action({
        dataroomId: dataroom.id,
        files,
        folders,
        customPermissionGroups,
        ...(fetchBySockets ? { onFinish, onError } : null),
        ...payload,
      });

      !fetchBySockets && onFinish(response);
    } catch (error) {
      !fetchBySockets && onError(error);
    }
  };

  const reset = () => {
    setDestinationFolder(null);
    setSuccessItems([]);
    setConflictItems([]);
    setIsFetching(false);
  };

  return {
    successItems,
    conflictItems,
    isFetching,
    destinationFolder,
    setDestinationFolder,
    fetch,
    reset,
  };
};

export default useActionWithConflict;
