import React, { useCallback } from 'react';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import InlineFieldWrp from '@/dataroom/ui/common/InlineFieldWrp';
import Input from '@/ui/shared/components/Form/Input';
import { Spinner, responsiveModalStyles } from '@dealroadshow/uikit';
import validate from './validator';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import styles from './linkForm.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';

interface IProps {
  initialValues: {},
  isFetching: boolean,
  submitButtonText: string,
  closeModal: () => void,
  onSubmit: (formData: {
    name: string,
    href: string,
  }) => Promise<{ href: string } | void>,
  onLoaded: () => void,
  name: string,
  footerRefCallback: () => void,
}

const LinkForm = ({
  initialValues,
  isFetching,
  submitButtonText,
  closeModal,
  onSubmit,
  onLoaded,
  name,
  footerRefCallback,
}: IProps) => {
  const { tenant } = useDataroomTenantContext();
  const handleFormSubmit = (formData) => onSubmit(formData).then((error) => {
    if (error) return error;
    closeModal();
    return null;
  });
  const { name: appName, hostname } = DataroomTenantConfig.fromCode(tenant);

  const renderForm: TFinalFormRenderProp = useCallback(({
    pristine, hasSubmitErrors, modifiedSinceLastSubmit, hasValidationErrors,
  }, {
    Field,
  }) => (
    <>
      <div className={ styles.contentWrp }>
        <InlineFieldWrp
          name="name"
          label="Display text"
          placeholder={ `e.g. ${ appName }` }
          className={ styles.inlineFieldWrp }
          formFieldClassName={ styles.formField }
          formLabelClassName={ styles.formLabel }
        >
          { (props) => (
            <Field
              { ...props }
              component={ Input }
              dataTest="newLinkNameInput"
              autoFocus
            />
          ) }
        </InlineFieldWrp>
        <InlineFieldWrp
          name="href"
          label="URL"
          placeholder={ `e.g. www.${ hostname }` }
          className={ styles.inlineFieldWrp }
          formFieldClassName={ styles.formField }
          formLabelClassName={ styles.formLabel }
        >
          { (props) => (
            <Field
              { ...props }
              dataTest="newLinkHrefInput"
            >
              { ({ meta, ...props }) => (
                /* @ts-ignore */
                <Input
                  { ...props }
                  meta={ {
                    ...meta,
                    error: meta.error || (!modifiedSinceLastSubmit && meta.submitError),
                    submitError: (!modifiedSinceLastSubmit && meta.submitError),
                  } }
                />
              ) }
            </Field>
          ) }
        </InlineFieldWrp>
        <Spinner
          overlay
          isVisible={ isFetching }
        />
      </div>
      <div
        className={ responsiveModalStyles.modalFooter }
        ref={ footerRefCallback }
      >
        <Button
          type="submit"
          variant={ variantTypes.action }
          disabled={ pristine || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit) || isFetching }
          title={ submitButtonText }
          dataTest={ `${ name }SubmitButton` }
        />
        <Button
          variant={ variantTypes.text }
          onClick={ closeModal }
          title="Cancel"
          dataTest={ `${ name }CancelButton` }
        />
      </div>
    </>
  ), [isFetching]);

  return (
    <FinalForm
      name="linkForm"
      dataTest="linkForm"
      initialValues={ initialValues }
      onSubmit={ handleFormSubmit }
      render={ renderForm }
      validate={ validate }
      onLibraryLoaded={ onLoaded }
    />
  );
};

export default LinkForm;
