// eslint-disable-next-line no-restricted-imports
import {
  Field,
  FormSpy,
  useField,
  useForm,
  useFormState,
} from 'react-final-form';
// eslint-disable-next-line no-restricted-imports
import {
  FieldArray,
  useFieldArray,
} from 'react-final-form-arrays';
import { IFinalFormApi } from '@/ui/shared/components/Form/FinalForm/interfaces';

/**
 * @deprecated Form API should be imported from ui/shared/components/FinalForm
 */
export const FINAL_FORM_API: IFinalFormApi = {
  Field,
  FormSpy,
  FieldArray,
  useField,
  useForm,
  useFormState,
  useFieldArray,
};
