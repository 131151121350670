import ErrorCodeHelper from '@finsight/error-codes';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';

/**
 * Retrieves real error from wrapped rpc error
 *
 *
 * @return {*}
 */
const parseWrappedError = () => (response) => {
  if (!(response instanceof RpcError)) {
    return response;
  }
  if (response.getCode() === ErrorCodeHelper.getCodeByName('WRAPPED_ERROR') && response.getData().code) {
    response = new RpcError({ error: { ...response.getData() } });
  }

  return response;
};

export default parseWrappedError;
