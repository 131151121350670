import config from '@/Framework/config';
import EVERCALL_URL from '@/evercall/Router/urlConstants';
/*
Yep, I know :(
But please look on this funny cat while trying to work with this.
         /\_/\
    ____/ o o \
  /~____  =ø= /
 (______)__m_m)
 */

/*
* Dynamic logout targets mapping. ORDER MATTERS!!!
* */
const logoutTargets = [
  { // Condor
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.condorSubdomain }\..*\/.*`),
    target: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login/condor`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login/condor`,
  },
  {
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.usersSubdomain }\..*\/profile.*`),
    target: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Retail roadshow
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.drsSubdomain }\..*\/(viewer\/|e\/).*\/retail`),
    target: `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/retail`,
    autoLogoutTarget: `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/retail`,
  },
  { // Cross deal analytics
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/analytics.*`),
    target: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Cross deal analytics
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/accounts.*`),
    target: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Roadshow
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/roadshow.*`),
    target: `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Investor viewer
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.drsSubdomain }\..*\/(viewer\/|e\/).*`),
    target: `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/`,
    autoLogoutTarget: `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/`,
  },
  { // DealVDR
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/dealvdr.*`),
    target: `${ config.protocol }//${ config.tenantDealvdrDomain }`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // 17g5
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/17g5.*`),
    target: `${ config.protocol }//${ config.tenant17g5Domain }/`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Investorset
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/investorset.*`),
    target: `${ config.protocol }//${ config.tenantInvestorSetDomain }/`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Verisend
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/verisend.*`),
    target: `${ config.protocol }//${ config.tenantVeriSendDomain }/`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Deal Manager Portal
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/.*`),
    target: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
    autoLogoutTarget: `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`,
  },
  { // Evercall Chat
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.evercallDomain }\..*\/.*`),
    target: `${ config.protocol }//${ config.evercallDomain }${ EVERCALL_URL.DASHBOARD }`,
    autoLogoutTarget: `${ config.protocol }//${ config.evercallDomain }${ EVERCALL_URL.DASHBOARD }`,
  },
];

export default logoutTargets;
