import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { systemFolders } from '@/dataroom/domain/filesystemPermissions';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

const useDisable = () => {
  const { container } = useDIContext();
  const { tenant } = useDataroomTenantContext();
  const { dataroom, getDataroomByName } = useDataroomContext();
  const { updateFolderTree } = useFolderTreeContext();
  const { currentFolder } = useCurrentFolderContext();
  const {
    isPristine: isDataroomExplorerCollectionPristine,
    updateCollection: updateDataroomExplorerCollection,
    softReset: resetDataroomExplorer,
  } = useDataroomExplorerContext();
  const [isFetching, setIsFetching] = useState(false);

  const disable = async (items: (IFilesystemListItem | IFolderTree)[]) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        audioUploading: items.some((item) => item.name === systemFolders.audioUpload),
        emailUploading: items.some((item) => item.name === systemFolders.emailUpload),
      };

      await container.get<FolderRepository>(FolderRepository).disable(payload);

      NotificationManager.success(getMessage(messageCodes.DATAROOM_SETTINGS_SAVE_SUCCESS, {
        prefix: DataroomTenantConfig.fromCode(tenant).options.tenantLabel,
      }));

      if (currentFolder) {
        isDataroomExplorerCollectionPristine ? updateDataroomExplorerCollection() : resetDataroomExplorer();
      }
      await getDataroomByName(dataroom.name);

      updateFolderTree(items[0].id);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    disable,
  };
};

export default useDisable;
