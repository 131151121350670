import React, { useState, useEffect } from 'react';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import NameField from './NameField';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import { Spinner, ResponsiveModal as Modal, Icon, IconType } from '@dealroadshow/uikit';
import styles from './preloadModal.scss';

interface IProps {
  closeModal: () => void,
}

const PreloadModal = (
  {
    closeModal,
  }: IProps,
) => {
  const { currentUser: { email: userEmail } } = useCurrentUserContext();
  const { tenant } = useDataroomTenantContext();
  const {
    archiveName,
    isArchiveNameFetching,
    generateDefaultArchiveName,
    requestDownload,
    requestDownloadProps,
    download,
    inputArchiveName,
    setInputArchiveName,
  } = useDownloadContext();
  const { supportEmail, supportPhoneNumber } = DataroomTenantConfig.fromCode(tenant);
  const [error, setError] = useState('');

  const email = (<strong>{ userEmail }</strong>);
  const phone = (<strong>{ supportPhoneNumber }</strong>);
  const support = (<a href={ `mailto:${ supportEmail }` } target="_blank">{ supportEmail }</a>);

  useEffect(() => {
    generateDefaultArchiveName();
  }, []);

  const handleDownload = () => {
    requestDownload(requestDownloadProps).then(download);
  };

  const content = (
    <div className={ styles.contentWrp }>
      <div className={ styles.description }>
        Depending on the size of this download, it may take a moment to generate the zip archive.
        For larger downloads, we will email { email } with a download link.
        You can also check the status and download the archive from the Downloads folder.
      </div>
      <div className={ styles.archiveSection }>
        <div className={ styles.archiveWrapper }>
          <Icon
            type={ IconType.fileArchive }
            className={ styles.archiveIcon }
          />
          <div className={ styles.title }>
            Your archive will be named: <strong>"{ archiveName }"</strong>
          </div>
          <NameField
            setCurrentArchiveName={ setInputArchiveName }
            currentArchiveName={ inputArchiveName }
            error={ error }
            setError={ setError }
          />
        </div>
      </div>
      <div className={ styles.support }>
        If you have any questions, please contact us at { phone } or { support }
      </div>
      <Spinner
        isVisible={ isArchiveNameFetching }
        overlay
      />
    </div>
  );

  const footer = (
    <>
      <Button
        variant={ variantTypes.action }
        title="Download"
        onClick={ handleDownload }
        dataTest="PreloadModalDownloadButton"
        disabled={ !!error }
      />
      <Button
        variant={ variantTypes.text }
        title="Cancel"
        dataTest="PreloadModalCancelButton"
        onClick={ closeModal }
      />
    </>
  );

  return (
    <Modal
      title="Your Download is in Queue"
      className={ styles.emailModal }
      isVisible
      onCloseClicked={ closeModal }
      footer={ footer }
      dataTest="preloadModal"
    >
      { content }
    </Modal>
  );
};

export default PreloadModal;
