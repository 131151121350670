import React from 'react';
import cn from 'classnames';
import Size from '@/dataroom/ui/common/DataroomExplorer/Columns/Size';
import FileUploaderItemActions
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/FileUploaderItemActions';
import { ProgressRing, Icon, IconType } from '@dealroadshow/uikit';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from './itemUploadStatus.scss';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';

interface IProps {
  cellProps: {
    row: FileWithUploadAndPath,
  },
}

const ItemUploadStatus = (props: IProps) => {
  const { isAddingChildren } = useFileUploaderContext();
  const { cellProps } = props;

  const file = cellProps.row;
  const { upload } = file;

  return (
    upload.status ? (
      <div className={ cn(alignStyles.alignRight && styles.wrapper) }>
        { upload.isSuccess() &&
          <Icon className={ styles.iconCheckActive } type={ IconType.checkCircle } /> }
        { (upload.isUploading() || upload.isPending() || upload.isJoining()) && (
          <ProgressRing
            radius={ 10 }
            stroke={ 2 }
            progress={ upload.percentage }
          />
        ) }
        { upload.isFailure() && !isAddingChildren && (
          <FileUploaderItemActions
            file={ file }
          >
            <Icon className={ styles.iconFailed } type={ IconType.cancelCircle } />
          </FileUploaderItemActions>
        ) }
      </div>
    ) : (
      <FileUploaderItemActions file={ file }>
        <Size value={ file.size } />
      </FileUploaderItemActions>
    )
  );
};

export default ItemUploadStatus;
