import ErrorCodeHelper from '@finsight/error-codes';
import { messageCodes } from '@/Framework/Message/messages';

export const FALLBACK = 'fallback';

const mapping = {
  fallback: messageCodes.GENERAL_ERROR,
  /**
   * Session
   */
  [ErrorCodeHelper.getCodeByName('SESSION_ACCESS_DENIED')]: messageCodes.SESSION_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('SESSION_CREATE_ERROR')]: messageCodes.GENERAL_ERROR,
  [ErrorCodeHelper.getCodeByName('SESSION_NOT_EXIST')]: messageCodes.GENERAL_ERROR,
  [ErrorCodeHelper.getCodeByName('SESSION_PERMISSION_DENIED')]: messageCodes.SESSION_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('SESSION_INVALID_PARAMS')]: messageCodes.SESSION_INVALID_CREDENTIALS,
  [ErrorCodeHelper.getCodeByName('SESSION_ATTEMPT_LIMIT')]: messageCodes.SESSION_ATTEMPT_LIMIT,
  [ErrorCodeHelper.getCodeByName('SESSION_NOT_ADMIN')]: messageCodes.SESSION_ACCESS_DENIED_NOT_ADMIN,
  [ErrorCodeHelper.getCodeByName('SESSION_SECURITY_LOG_OUT')]: messageCodes.SECURITY_LOG_OUT,
  /**
   * User
   */
  [ErrorCodeHelper.getCodeByName('USER_ERROR')]: FALLBACK,
  [ErrorCodeHelper.getCodeByName('USER_PROFILE_INCOMPLETE')]: messageCodes.SESSION_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('USER_NOT_ACTIVATED')]: messageCodes.USER_ACCOUNT_NOT_ACTIVATED,
  [ErrorCodeHelper.getCodeByName('USER_ACTIVATION_LINK_NOT_VALID')]: messageCodes.USER_ACTIVATION_LINK_NOT_VALID,
  [ErrorCodeHelper.getCodeByName('USER_RESET_PASSWORD_HASH_NOT_VALID')]: messageCodes.USER_RESET_PASSWORD_HASH_NOT_VALID,
  [ErrorCodeHelper.getCodeByName('USER_ACTIVATION_EMAIL_SENT')]: messageCodes.USER_ACTIVATION_EMAIL_SENT,
  [ErrorCodeHelper.getCodeByName('USER_NOT_EXIST')]: messageCodes.SESSION_INVALID_CREDENTIALS,
  [ErrorCodeHelper.getCodeByName('USER_INVALID_PASSWORD')]: messageCodes.SESSION_INVALID_CREDENTIALS,
  [ErrorCodeHelper.getCodeByName('USER_PASSWORD_CHECK_FAILED')]: messageCodes.USER_PASSWORD_CHECK_FAILED,
  [ErrorCodeHelper.getCodeByName('USER_ALREADY_REGISTERED')]: messageCodes.USER_ALREADY_REGISTERED,
  [ErrorCodeHelper.getCodeByName('USER_DOMAIN_BLACKLISTED')]: messageCodes.SESSION_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('USER_BANNED')]: messageCodes.USER_BANNED,
  [ErrorCodeHelper.getCodeByName('CONTACT_NOT_FOUND')]: messageCodes.CONTACT_NOT_FOUND,
  /**
   * Roadshow
   */
  [ErrorCodeHelper.getCodeByName('ROADSHOW_NOT_FOUND')]: messageCodes.ROADSHOW_INACCESSIBLE,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_EXPIRED')]: messageCodes.ROADSHOW_INACCESSIBLE,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_ENTRY_CODE_INACCESSIBLE')]: messageCodes.ROADSHOW_INACCESSIBLE,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_ACCESS_DENIED')]: messageCodes.ROADSHOW_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_PERMISSION_DENIED')]: messageCodes.ROADSHOW_PERMISSION_DENIED,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_KICKED_USER')]: messageCodes.ROADSHOW_KICKED_USER,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_VIEW_TIME_LIMIT_REACHED')]: messageCodes.ROADSHOW_VIEW_TIME_LIMIT_REACHED,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_EVERCALL_CHAT_ID_WAS_NOT_BOOKED')]: messageCodes.ROADSHOW_EVERCALL_CHAT_ID_WAS_NOT_BOOKED,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_EVERCALL_CHAT_ID_DUPLICATE')]: messageCodes.ROADSHOW_EVERCALL_CHAT_ID_DUPLICATE,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_EVERCALL_CHAT_ID_INVALID_LENGTH')]: messageCodes.ROADSHOW_EVERCALL_CHAT_ID_INVALID_LENGTH,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_EVERCALL_CHAT_ID_INVALID_CODE')]: messageCodes.ROADSHOW_EVERCALL_CHAT_ID_INVALID_CODE,
  [ErrorCodeHelper.getCodeByName('EVERCALL_OACC_CONFERENCE_PARTICIPANT_NOT_FOUND')]: messageCodes.EVERCALL_OACC_CONFERENCE_PARTICIPANT_NOT_FOUND,
  [ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_CONFERENCE_ALREADY_LINKED')]: messageCodes.EVERCALL_ADMIN_CONFERENCE_ALREADY_LINKED,
  [ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_ROADSHOW_ALREADY_LINKED')]: messageCodes.EVERCALL_ADMIN_ROADSHOW_ALREADY_LINKED,

  /**
   * Allocate
   */
  [ErrorCodeHelper.getCodeByName('ALLOCATE_ACCESS_DENIED')]: messageCodes.ALLOCATE_ACCESS_DENIED,

  /**
   * Orderbook
   */
  [ErrorCodeHelper.getCodeByName('ORDER_BOOK_ACCESS_DENIED')]: messageCodes.ORDER_BOOK_ACCESS_DENIED,

  /**
   * Dataroom
   */
  [ErrorCodeHelper.getCodeByName('DATAROOM_USER_NOT_ADDED_TO_DATAROOM')]: messageCodes.DATAROOM_USER_NOT_ADDED_TO_DATAROOM,
  [ErrorCodeHelper.getCodeByName('DATAROOM_USER_HAS_NO_ACCESS_GROUP')]: messageCodes.DATAROOM_USER_HAS_NO_ACCESS_GROUP,
  [ErrorCodeHelper.getCodeByName('DATAROOM_DEACTIVATED_DATAROOM_VIOLATION')]: messageCodes.DATAROOM_DEACTIVATED_DATAROOM_VIOLATION,
  [ErrorCodeHelper.getCodeByName('DATAROOM_CREATION_ERROR')]: messageCodes.DATAROOM_CREATION_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_CREATION_INPUT_VALIDATION_ERROR')]: messageCodes.DATAROOM_CREATION_INPUT_VALIDATION_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_CREATION_SAVE_ERROR')]: messageCodes.DATAROOM_CREATION_SAVE_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_PLAN_NOT_FOUND')]: messageCodes.DATAROOM_PLAN_NOT_FOUND,
  [ErrorCodeHelper.getCodeByName('DATAROOM_UPDATE_UPLOAD_NOTIFICATION_SETTINGS_ERROR')]: messageCodes.DATAROOM_UPDATE_UPLOAD_NOTIFICATION_SETTINGS_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_UPDATE_DIGEST_NOTIFICATION_SETTINGS_ERROR')]: messageCodes.DATAROOM_UPDATE_DIGEST_NOTIFICATION_SETTINGS_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_USERS_NOT_ADDED_TO_DATAROOM_ERROR')]: messageCodes.DATAROOM_USERS_NOT_ADDED_TO_DATAROOM_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_LOGO_PROCESSING_ERROR')]: messageCodes.DATAROOM_LOGO_PROCESSING_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_LOGO_NOT_FOUND_ERROR')]: messageCodes.DATAROOM_LOGO_NOT_FOUND_ERROR,
  [ErrorCodeHelper.getCodeByName('DATAROOM_DEACTIVATED_ARCHIVE_NOT_FOUND')]: messageCodes.DATAROOM_DEACTIVATED_ARCHIVE_NOT_FOUND,
  [ErrorCodeHelper.getCodeByName('DATAROOM_DEACTIVATED_ARCHIVE_NO_ACCESS')]: messageCodes.DATAROOM_DEACTIVATED_ARCHIVE_NO_ACCESS,
  [ErrorCodeHelper.getCodeByName('DATAROOM_DEACTIVATED_ARCHIVE_EXPIRED')]: messageCodes.DATAROOM_DEACTIVATED_ARCHIVE_EXPIRED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_MORE_THAN_ONE_LARGE_ARCHIVE_REQUESTED')]: messageCodes.DATAROOM_MORE_THAN_ONE_LARGE_ARCHIVE_REQUESTED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_BUILD_EXPORT_EXPIRED')]: messageCodes.DATAROOM_BUILD_EXPORT_EXPIRED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_BUILD_ARCHIVE_EXPIRED')]: messageCodes.DATAROOM_BUILD_ARCHIVE_EXPIRED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_BUILD_EXPORT_NOT_COMPLETED')]: messageCodes.DATAROOM_BUILD_EXPORT_NOT_COMPLETED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_BUILD_ARCHIVE_NOT_COMPLETED')]: messageCodes.DATAROOM_BUILD_ARCHIVE_NOT_COMPLETED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_FILE_ACCESS_DENIED')]: messageCodes.DATAROOM_FILE_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_FILE_NOT_FOUND')]: messageCodes.DATAROOM_FILE_NOT_FOUND,
  [ErrorCodeHelper.getCodeByName('INVALID_ARGUMENTS')]: messageCodes.INVALID_ARGUMENTS,
  [ErrorCodeHelper.getCodeByName('DATAROOM_PERMISSION_GROUP_ALREADY_EXIST')]: messageCodes.DATAROOM_PERMISSION_GROUP_ALREADY_EXIST,
  [ErrorCodeHelper.getCodeByName('DATAROOM_PREVIEW_CONVERSION_IN_PROGRESS')]: messageCodes.DATAROOM_PREVIEW_CONVERSION_IN_PROGRESS,
  // TO DO:
  // [ErrorCodeHelper.getCodeByName('DATAROOM_USER_NOTIFICATION_MANAGE_ERROR')]:
  // messageCodes.DATAROOM_USER_NOTIFICATION_MANAGE_ERROR,

  /**
   * Bulk entry codes
   */
  [ErrorCodeHelper.getCodeByName('ENTRY_CODE_LIMIT_REACHED')]: messageCodes.ERROR_MAX_NUMBER_CODES_EXCEEDED,

  /**
   * Roadshow investor
   */
  [ErrorCodeHelper.getCodeByName('ACCESS_IS_RESTRICTED')]: messageCodes.INVESTOR_ACCESS_RESTRICTED,
  [ErrorCodeHelper.getCodeByName('ROADSHOW_LOGIN_LIMIT_REACHED')]: messageCodes.INVESTOR_LOGIN_LIMIT_EXCEED,
  [ErrorCodeHelper.getCodeByName('INVESTOR_VERIFICATION_EMAIL_SENT')]: messageCodes.INVESTOR_VERIFICATION_EMAIL_SENT,
  [ErrorCodeHelper.getCodeByName('INVESTOR_EMAIL_NOT_VERIFIED')]: messageCodes.INVESTOR_EMAIL_NOT_VERIFIED,
  [ErrorCodeHelper.getCodeByName('INVESTOR_MOBILE_RESTRICTED')]: messageCodes.INVESTOR_MOBILE_RESTRICTED,
  [ErrorCodeHelper.getCodeByName('INVESTOR_NOT_CORPORATE_EMAIL')]: messageCodes.INVESTOR_NOT_CORPORATE_EMAIL,

  /**
   * Finsight
   */
  [ErrorCodeHelper.getCodeByName('FINSIGHT_BOND_MANAGER_ACCESS_CONTACT_NOT_FOUND')]: messageCodes.FINSIGHT_BOND_MANAGER_ACCESS_CONTACT_NOT_FOUND,
  [ErrorCodeHelper.getCodeByName('FINSIGHT_BOND_MANAGER_ACCESS_SAVE_ERROR')]: messageCodes.FINSIGHT_BOND_MANAGER_ACCESS_SAVE_ERROR,
  [ErrorCodeHelper.getCodeByName('FINSIGHT_BOND_MANAGER_ACCESS_DUPLICATED_CONTACT')]: messageCodes.FINSIGHT_BOND_MANAGER_ACCESS_DUPLICATED_CONTACT,
  [ErrorCodeHelper.getCodeByName('CONDOR_ACCOUNT_MIGRATION_LOCKED')]: messageCodes.CONDOR_ACCOUNT_MIGRATION_LOCKED,
  [ErrorCodeHelper.getCodeByName('FINSIGHT_FILING_UPDATE_STATUS_ERROR')]: messageCodes.FINSIGHT_FILING_UPDATE_STATUS_ERROR,

  /**
   * SSO
   */
  [ErrorCodeHelper.getCodeByName('SSO_ASSERTION_FAIL')]: messageCodes.SSO_ASSERTION_FAIL,

  /**
   * Two factor Authorization
   */
  [ErrorCodeHelper.getCodeByName('TWO_FACTOR_CODE_DELIVERY_FAIL')]: messageCodes.TWO_FACTOR_CODE_DELIVERY_FAIL,
  [ErrorCodeHelper.getCodeByName('TWO_FACTOR_CODE_EXPIRED')]: messageCodes.TWO_FACTOR_CODE_EXPIRED,
  [ErrorCodeHelper.getCodeByName('TWO_FACTOR_CODE_INVALID_VALUE')]: messageCodes.TWO_FACTOR_CODE_INVALID_VALUE,
  [ErrorCodeHelper.getCodeByName('TWO_FACTOR_FAIL_ATTEMPT')]: messageCodes.TWO_FACTOR_FAIL_ATTEMPT,
  [ErrorCodeHelper.getCodeByName('TWO_FACTOR_INVALID_MOBILE_PHONE')]: messageCodes.TWO_FACTOR_INVALID_MOBILE_PHONE,

  /**
   * Q&A
   */
  [ErrorCodeHelper.getCodeByName('QNA_CATEGORY_NAME_DUPLICATE')]: messageCodes.QNA_CATEGORY_NAME_DUPLICATE,
  [ErrorCodeHelper.getCodeByName('QNA_QUESTION_NOT_FOUND')]: messageCodes.QNA_QUESTION_NOT_FOUND,
  [ErrorCodeHelper.getCodeByName('QNA_ACCESS_DENIED')]: messageCodes.QNA_ACCESS_DENIED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_QNA_QUESTION_LIMIT_EXCEEDED')]: messageCodes.DATAROOM_QNA_QUESTION_LIMIT_EXCEEDED,
  [ErrorCodeHelper.getCodeByName('DATAROOM_QNA_QUESTION_LIMIT_REACHED')]: messageCodes.DATAROOM_QNA_QUESTION_LIMIT_REACHED,
  /**
   * FILE UPLOAD
   */
  [ErrorCodeHelper.getCodeByName('ROADSHOW_FILE_RESTRICTED')]: messageCodes.ROADSHOW_FILE_RESTRICTED,
};
export default mapping;
