import React from 'react';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import styles from './emailModal.scss';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

interface IProps {
  closeModal: () => any,
}

const EmailModal = ({ closeModal }: IProps) => {
  const { currentUser: { email: userEmail } } = useCurrentUserContext();
  const { tenant } = useDataroomTenantContext();
  const { supportEmail, supportPhoneNumber } = DataroomTenantConfig.fromCode(tenant);

  const email = (<strong>{ userEmail }</strong>);
  const phone = (<strong>{ supportPhoneNumber }</strong>);
  const support = (<a href={ `mailto:${ supportEmail }` } target="_blank">{ supportEmail }</a>);

  const content = (
    <div className={ styles.contentWrp }>
      <div className={ styles.description }>
        Due to the size of this bulk download, it will take us a few minutes to generate the zip archive.
        When this process is complete, we will email you at { email } with a link
        to complete the download. You can also download the archive from the Downloads folder.
      </div>
      <div className={ styles.support }>
        If you have any questions, please contact us at
        <div>{ phone } or { support }</div>
      </div>
    </div>
  );

  const footer = (
    <>
      <Button
        variant={ variantTypes.action }
        title="Ok"
        onClick={ closeModal }
        dataTest="downloadEmailCloseModalButton"
      />
    </>
  );

  return (
    <Modal
      title="Your Download is in Queue"
      className={ styles.emailModal }
      isVisible
      onCloseClicked={ closeModal }
      footer={ footer }
      modalTitleClassName={ styles.title }
      dataTest="downloadInQueueModal"
    >
      { content }
    </Modal>
  );
};

export default EmailModal;
