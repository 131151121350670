import React from 'react';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import styles from './exportTimeoutModal.scss';

interface IProps {
  closeModal: () => void,
}

const ExportTimeoutModal = ({ closeModal }: IProps) => {
  const { tenant } = useDataroomTenantContext();
  const { currentUser: { email: userEmail } } = useCurrentUserContext();
  const { supportEmail, supportPhoneNumber } = DataroomTenantConfig.fromCode(tenant);

  return (
    <Modal
      className={ styles.exportTimeoutModal }
      onCloseClicked={ closeModal }
      title="Your Export is in Queue"
      dataTest="exportTimeoutModal"
      footer={ (
        <Button
          variant={ variantTypes.action }
          onClick={ closeModal }
          title="OK"
          dataTest="exportTimeoutOkButton"
        />
      ) }
      isVisible
    >
      <div className={ styles.contentWrp }>
        <p>
          Due to the size of this export, it will take us a few minutes to aggregate and generate the report.
          When this process is complete, we will email you at { userEmail } with a link to complete the download.
        </p>
        <p>
          If you have any questions, please contact us at { supportPhoneNumber } or { supportEmail }.
          Thank you for your patience.
        </p>
      </div>
    </Modal>
  );
};

export default ExportTimeoutModal;
