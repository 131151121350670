import React, { createContext, useContext, useState, useEffect } from 'react';
import { getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import NotificationManager from '@/ui/shared/components/Notification/NotificationManager';
import { useRouter } from 'next/router';
import { History } from 'history';

interface IError {
  code: string,
  data: object,
}

interface IProps {
  children: React.ReactNode,
  history?: History,
}

const useGlobalError = (history?: History) => {
  const [isGlobalErrorVisible, setIsGlobalErrorVisible] = useState<boolean>(false);
  const [error, setError] = useState<IError | {}>(null);
  const [message, setMessage] = useState<string>(null);
  const nextRouter = useRouter();
  const { query, events, pathname, asPath } = nextRouter;
  const currentPathname = history?.location?.pathname || asPath;
  const replace = history?.replace || nextRouter.replace;

  useEffect(() => {
    checkGlobalErrors([]);
    events.on('routeChangeComplete', checkGlobalErrors);
    if (history) {
      history.listen(checkGlobalErrors);
    }

    return () => {
      events.off('routeChangeComplete', checkGlobalErrors);
    };
  });

  const checkGlobalErrors = (params) => {
    const uri = (query.any as string[] || []).join('/');
    // Handle next
    if (typeof params === 'string' && currentPathname !== params) {
      hideGlobalError();
    }
    // Handle react router
    if (history && !!params.pathname && params.pathname !== currentPathname) {
      hideGlobalError();
    }

    const errorCode = +query.error_code;
    if (!errorCode) {
      return;
    }
    const rawErrorData = query.error_data as string;
    const errorData = rawErrorData ? JSON.parse(decodeURIComponent(rawErrorData)) : {};
    const showToast = query.show_toast;

    const errorMessage = getMessageByErrorCode(errorCode, errorData);

    delete query.any;
    delete query.error_code;
    delete query.error_data;
    delete query.show_toast;

    if (showToast) {
      NotificationManager.error(errorMessage);
      return;
    }
    showGlobalError(errorMessage, { code: errorCode, data: errorData });

    const search = Object.keys(query).map((key) => `${ key }=${ encodeURIComponent(query[key] as string) }`);

    replace({ pathname: pathname + uri, search: search ? `?${ search.join('&') }` : null });
  };

  const showGlobalError = (message: string, error: IError | {} = {}) => {
    setError(error);
    setMessage(message);
    setIsGlobalErrorVisible(true);
  };

  const hideGlobalError = () => {
    setIsGlobalErrorVisible(false);
  };

  return {
    isGlobalErrorVisible,
    error,
    message,
    hideGlobalError,
  };
};

type GlobalErrorContextType = ReturnType<typeof useGlobalError>;

export const GlobalErrorContext = createContext<GlobalErrorContextType>(null);

export const useGlobalErrorContext = (): GlobalErrorContextType => {
  const context = useContext<GlobalErrorContextType>(GlobalErrorContext);
  if (!context) {
    throw new Error('useGlobalErrorContext must be used within a GlobalErrorContextProvider');
  }
  return context;
};

const GlobalErrorContextProvider = ({ children, history }: IProps) => (
  <GlobalErrorContext.Provider value={ { ...useGlobalError(history) } }>
    { children }
  </GlobalErrorContext.Provider>
);

export default ({ children, history }: IProps) => (
  <GlobalErrorContextProvider history={ history }>
    { children }
  </GlobalErrorContextProvider>
);
