import React from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import styles from './menuItem.scss';

interface IProps {
  className: string,
  onClick?: () => void,
  children: React.ReactNode,
  dataTest: string,
}

const MenuItem = ({
  children,
  className,
  onClick = noop,
  dataTest,
}: IProps) => (
  <div
    data-test={ dataTest }
    className={ cn(styles.menuItem, className) }
    onClick={ onClick }
  >
    { children }
  </div>
);

export default MenuItem;
