interface IClientReqData {
  ssid?: string,
  'xFinsightClientIp'?: string,
  'userAgent'?: string,
  currentHost?: string,
  cookie?: string,
  fingerprint?: string,
  serverName?: string,
}

class ClientReqData {
  constructor(private _headers) {}

  public static fromRequest({ headers, cookies }, serverName = ''): ClientReqData {
    const { ssid, fingerprint = null } = cookies;
    const {
      'x-finsight-client-ip': xFinsightClientIp,
      'user-agent': userAgent,
      host: currentHost,
      referer = '',
      ...otherHeaders
    } = headers;

    return new ClientReqData({
      ssid,
      xFinsightClientIp,
      currentHost,
      userAgent,
      fingerprint,
      referer,
      serverName,
      ...otherHeaders,
    });
  }

  get headers(): IClientReqData {
    return this._headers;
  }

  get ssrHeaders(): Headers {
    const {
      xFinsightClientIp,
      userAgent,
      serverName,
      cookie,
      referer,
      host,
      via: clientVia,
    } = this._headers;

    const headers = new Headers();
    headers.append('Host', host);
    headers.append('User-Agent', userAgent);
    headers.append('X-Finsight-Client-IP', xFinsightClientIp);
    headers.append('X-Referer', referer);
    headers.append('Referer', referer);
    headers.append('Cookie', cookie);

    const newViaHeaders = clientVia ? `${ clientVia }, ${ serverName }` : serverName;
    headers.append('Via', newViaHeaders);
    return headers;
  }

  get userAgent(): string {
    return this._headers?.userAgent;
  }

  get currentHost(): string {
    return this._headers?.currentHost;
  }
}

export default ClientReqData;
