import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import styles from './contextMenuItem.scss';

interface IProps {
  iconType: IconType,
  title: string,
  isWarning?: boolean,
  isDisabled?: boolean,
}

const ContextMenuItem = ({ iconType, title, isWarning, isDisabled }: IProps) => (
  <div className={ cn(styles.item, {
      [styles.isWarning]: isWarning,
      [styles.isDisabled]: isDisabled,
    }) }
  >
    <Icon
      type={ iconType }
      className={ styles.icon }
    />
    <span className={ styles }>{ title }</span>
  </div>
);

export default ContextMenuItem;
