import { useState, useCallback, useMemo, useEffect } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import { Icon, IconType } from '@dealroadshow/uikit';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';
import style from './headerBanner.scss';

import { useHeaderBannerContext } from './HeaderBannerContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import BannersRepository from '@/condor/infrastructure/repository/banners/BannersRepository';
import useElementSize from '@/ui/shared/hooks/useElementSize';

interface IProps {
  onClose?: () => void,
  afterClose?: () => void,
  isSticky?: boolean,
  shouldUseContentWrapAsScrollable?: boolean,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const HeaderBanner = ({
  onClose = noop,
  afterClose = noop,
  isSticky = true,
  shouldUseContentWrapAsScrollable = false,
}: IProps) => {
  const bannerRepository = useDIContext().container.get(BannersRepository);
  const [isFixed, setIsFixed] = useState(false);
  const { height: bannerHeight } = useElementSize('banner');

  const [isShowBanner, setIsShowBanner] = useState(true);

  const { hash, color, content } = useHeaderBannerContext();

  const scrollHandler = useCallback(throttle(() => {
    setIsFixed(window.scrollY > 0);
  }, 100), []);

  const wrapperScrollHandler = useCallback(throttle(() => {
    const el = document.getElementById(CONTENT_WRP_ID);
    setIsFixed(el.scrollTop > 0);
  }, 100), []);

  useEffect(() => {
    if (isSticky && content) {
      if (shouldUseContentWrapAsScrollable) {
        const el = document.getElementById(CONTENT_WRP_ID);
        el?.addEventListener('scroll', wrapperScrollHandler);
      } else {
        window.addEventListener('scroll', scrollHandler);
      }
    }
    return () => {
      if (shouldUseContentWrapAsScrollable) {
        const el = document.getElementById(CONTENT_WRP_ID);
        if (el) {
          el.removeEventListener('scroll', wrapperScrollHandler);
        }
      } else {
        window.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [isSticky, content, shouldUseContentWrapAsScrollable]);

  useEffect(() => {
    if (bannerRepository.getClosedBanners().includes(hash)) {
      setIsShowBanner(false);
    }
  }, [hash]);

  const closeCallback = () => {
    onClose();
    setTimeout(() => {
      afterClose();
    }, style.animationSpeed);
  };

  const onCloseBanner = useCallback(() => {
    setIsShowBanner(false);
    bannerRepository.setClosedBanners(hash);
    closeCallback();
  }, [onClose, afterClose, hash]);

  const bannerContent = useMemo(() => (
    <div className={ style.ieWrapper } id="banner">
      <div
        className={ cn(style.container, { [style.closedBanner]: !isShowBanner }) }
        style={ { backgroundColor: color } }
      >
        <div
          data-test="bannerContent"
          className={ style.content }
          dangerouslySetInnerHTML={ { __html: content } }
        />
        <Icon
          className={ style.closeIcon }
          type={ IconType.close }
          onClick={ onCloseBanner }
          data-test="closeBannerIcon"
        />
      </div>
    </div>
  ), [isShowBanner, content, onCloseBanner]);

  if (!content) {
    return null;
  }

  return (
    <>
      { isFixed && <div style={ { height: bannerHeight } } /> }
      <div
        className={ cn(style.headerBanner, {
          [style.headerBannerFixed]: isFixed,
        }) }
        data-test="headerBanner"
      >
        { bannerContent }
      </div>
    </>
  );
};

export default HeaderBanner;
