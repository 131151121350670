import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { createFilter } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import AsyncSelect from '@/ui/shared/components/Form/Select/legacy/ReactAsyncSelect';
import FilesystemIcon from '@/dataroom/ui/common/FilesystemIcon';
import { IFile } from '@/dataroom/domain/questions/vo/File';
import styles from './selectFile.scss';

interface IProps {
  files: {
    searchFiles: (search: string) => Promise<IFile[]>,
  },
  handleCancel: () => void,
  handleSubmitFile: (file: IFile) => void,
  debounceDelay?: number,
  label?: string,
  submitButtonTitle?: string,
  submitButtonVariant?: string,
  containerClassName?: string,
  selectClassName?: string,
}

const SelectFile = ({
  files,
  handleSubmitFile,
  handleCancel,
  debounceDelay = 500,
  label,
  submitButtonTitle = 'Ok',
  submitButtonVariant = variantTypes.outline,
  containerClassName,
  selectClassName,
}: IProps) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isInputValueVisible, setIsInputValueVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { searchFiles } = files;

  const showInputValue = () => {
    setIsInputValueVisible(true);
    setInputValue(selectedFile ? selectedFile.name : '');
  };

  const optionRender = (option: IFile) => (
    <div>
      <FilesystemIcon
        extension={ option.extension }
        type={ option.type }
        className={ styles.filesystemIcon }
      />
      { option.index ? `${ option.index } ${ option.name }` : option.name }
    </div>
  );

  const loadOptions = useCallback(debounce((query: string, callback: () => void) => {
    searchFiles(query).then(callback);
  }, debounceDelay), []);

  const handleSelectFile = (file: IFile) => {
    !!file && setIsInputValueVisible(false);
    setSelectedFile(file);
  };

  const handleInputChange = (t: string, { action }: { action: string }) => {
    action === 'input-change' && setInputValue(t);
  };

  return (
    <div
      className={ cn(styles.selectFileWrp, containerClassName) }
      data-test="selectFileBlock"
    >
      <AsyncSelect
        dataTest="fileSearchSelect"
        placeholder="Type folder or file name"
        noOptionsMessage={ () => 'No matches' }
        filterOption={ createFilter({ ignoreCase: true, stringify: (option: FilterOptionOption<any>) => `${ option.data.index } ${ option.data.name }` }) }
        formFieldClassName={ cn(styles.selectInput, { [styles.withFile]: !!selectedFile }) }
        onChange={ handleSelectFile }
        loadOptions={ loadOptions }
        value={ selectedFile }
        label={ label }
        selectClassName={ selectClassName }
        getOptionLabel={ (option) => option.name | option.index }
        getOptionValue={ (option) => `${ option.id }${ option.type }` }
        formatOptionLabel={ optionRender }
        components={ {
          DropdownIndicator: () => null,
        } }
        autoload={ false }
        cache={ false }
        inputValue={ isInputValueVisible ? inputValue : '' }
        onInputChange={ handleInputChange }
        onInputFocus={ showInputValue }
        onMenuOpen={ showInputValue }
        onBlur={ () => setIsInputValueVisible(false) }
        isNarrow
      />
      <Button
        type="button"
        variant={ submitButtonVariant }
        className={ styles.okButton }
        disabled={ !selectedFile }
        onClick={ () => handleSubmitFile(selectedFile) }
        title={ submitButtonTitle }
        dataTest="okButton"
      />
      <Button
        type="button"
        variant={ variantTypes.text }
        className={ styles.cancelButton }
        onClick={ handleCancel }
        title="Cancel"
        dataTest="cancelButton"
      />
    </div>
  );
};

export default SelectFile;
