import React from 'react';
import config from '@/Framework/config';
import FINSIGHT_URLS from '@/finsight/Router/urlConstants';
import isServer from '@/Framework/Router/Next/isServer';
import { Icon, IconType } from '@dealroadshow/uikit';
import Link from '@/ui/shared/components/ReactRouter/Link';
import styles from './fallback.scss';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

const onReloadClick = () => isServer() && window.location.reload();

interface IProps {
  className?: string,
}

const getContactLink = () => {
  /**
   * if we're on finsight and router is reachable - allow redirect via router to avoid re-render
   * otherwise - simple anchor tag
   */
  if (window.location.hostname === config.tenant.finsight.hostname) {
    return <Link to={ FINSIGHT_URLS.CONTACT }>contact</Link>;
  }

  return <a href={ finsightWebUrl.getContactUsUrl() }>contact</a>;
};

const Fallback = ({ className }: IProps) => (
  <div className={ className }>
    <div className={ styles.fallbackContainer }>
      <div className={ styles.messageWrapper }>
        Something went wrong loading this component.
        Please { getContactLink() } support or <a onClick={ onReloadClick }>reload</a>
        <Icon
          type={ IconType.rotate }
          className={ styles.reloadIcon }
          onClick={ onReloadClick }
        />
      </div>
    </div>
  </div>
);

export default Fallback;
