import React, { useState } from 'react';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { RadioGroup, Radio, ResponsiveModal as Modal } from '@dealroadshow/uikit';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { isFileIndexingEnabled } from '@/dataroom/domain/filesystem';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { Area } from '@/dataroom/domain/vo/Area';
import {
  enabledIndexingItems,
  disabledIndexingItems,
  EnabledIndexingKeys,
  DisabledIndexingKeys,
} from '../../constants';
import styles from './truncationModal.scss';

interface IProps {
  areas: Area[],
  onSubmit: (truncationMode: string, stagingTruncationMode: string) => any,
  closeModal: () => any,
}

const enabledIndexMapping = {
  IndexingEnabledByFolderFileName: DisabledIndexingKeys.IndexingDisabledByFolderFileName,
  IndexingEnabledByIndex: DisabledIndexingKeys.None,
  IndexingEnabledDeleteAllNames: DisabledIndexingKeys.None,
  None: DisabledIndexingKeys.None,
};

const disabledIndexMapping = {
  IndexingDisabledByFolderFileName: EnabledIndexingKeys.IndexingEnabledByFolderFileName,
  None: EnabledIndexingKeys.None,
};

const TruncationModal = ({ areas = [], onSubmit, closeModal }: IProps) => {
  const { dataroom } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();

  const { name: appName, options: { tenantLabelSm } } = DataroomTenantConfig.fromCode(tenant);

  const isPrimaryFileIndexEnabled = isFileIndexingEnabled(dataroom, Area.Primary);
  const isStagingFileIndexEnabled = isFileIndexingEnabled(dataroom, Area.Staging);

  const shouldUseEnabledFileIndexOptions = (isPrimaryFileIndexEnabled && areas.includes(Area.Primary)) ||
    (isStagingFileIndexEnabled && areas.includes(Area.Staging));

  const items = shouldUseEnabledFileIndexOptions ? enabledIndexingItems : disabledIndexingItems;

  const [truncationMode, setTruncationMode] = useState(items[0].key);

  const [primaryTruncationMode, setPrimaryTruncationMode] = useState(
    isPrimaryFileIndexEnabled
      ? EnabledIndexingKeys.IndexingEnabledByFolderFileName
      : DisabledIndexingKeys.IndexingDisabledByFolderFileName,
  );
  const [stagingTruncationMode, setStagingTruncationMode] = useState(
    isStagingFileIndexEnabled
      ? EnabledIndexingKeys.IndexingEnabledByFolderFileName
      : DisabledIndexingKeys.IndexingDisabledByFolderFileName,
  );

  const handleTruncationModeChange = (mode) => {
    setTruncationMode(mode);

    if (isPrimaryFileIndexEnabled === isStagingFileIndexEnabled) {
      setPrimaryTruncationMode(mode);
      setStagingTruncationMode(mode);
      return;
    }

    const mapping = shouldUseEnabledFileIndexOptions ? enabledIndexMapping : disabledIndexMapping;

    setPrimaryTruncationMode(isPrimaryFileIndexEnabled ? mode : mapping[mode]);
    setStagingTruncationMode(isStagingFileIndexEnabled ? mode : mapping[mode]);
  };

  const content = (
    <div className={ styles.contentWrp }>
      <div className={ styles.description }>
        Most operating systems have a 260 character limit for a file path. We detect
        this download may contain file path lengths that exceed this limit, which will trigger a systems error when you
        attempt to unzip the { tenantLabelSm } archive. Would you like { appName } to
        truncate these file paths to prevent this error?
      </div>
      <RadioGroup
        selectedValue={ truncationMode }
        fieldClassName={ styles.radioGroupWrp }
        className={ styles.radioGroup }
        onChange={ handleTruncationModeChange }
        dataTest="truncationModalRadioGroup"
      >
        {
          items.map(({ key, label, dataTest }) => (
            <Radio
              key={ key }
              label={ label }
              value={ key }
              className={ styles.radio }
              dataTest={ dataTest }
            />
          ))
        }
      </RadioGroup>
    </div>
  );

  const footer = (
    <>
      <Button
        variant={ variantTypes.action }
        title="Download"
        onClick={ () => onSubmit(primaryTruncationMode, stagingTruncationMode) }
        dataTest="downloadButton"
      />
      <Button
        variant={ variantTypes.text }
        onClick={ closeModal }
        title="Cancel"
        dataTest="cancelButton"
      />
    </>
  );

  return (
    <Modal
      title="Shorten File Path?"
      className={ styles.truncationModal }
      isVisible
      onCloseClicked={ closeModal }
      footer={ footer }
      dataTest="longFilePathModal"
    >
      { content }
    </Modal>
  );
};

export default TruncationModal;
