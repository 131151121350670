import cn from 'classnames';
import { Component } from 'react';
import Div100vh from '@/ui/shared/components/Layout/Div100vh';
import styles from './applicationWrp.scss';
import { APPLICATION_WRP_ID } from './constants';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

class ApplicationWrp extends Component<IProps> {
  // eslint-disable-next-line react/sort-comp
  private divRef: any;

  constructor(props: IProps) {
    super(props);

    this.divRef = null;
  }

  render() {
    return (
      <Div100vh
        id={ APPLICATION_WRP_ID }
        className={ cn(styles.wrp, this.props.className) }
        ref={ (ref) => {
          this.divRef = ref;
        } }
      >
        { this.props.children }
      </Div100vh>
    );
  }
}

export default ApplicationWrp;
