import useRouter from '@/ui/shared/hooks/useNextRouter';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import getFolderUrl from '@/dataroom/infrastructure/getFolderUrl';

const useDataroomNavigation = () => {
  const { push, replace } = useRouter();

  const goToFolder = (
    dataroomName: string,
    folderId: number,
    saveHistory: boolean = false,
    isStaging: boolean = false,
  ) => {
    if (saveHistory) {
      push(getFolderUrl(dataroomName, folderId, isStaging));
    } else {
      replace(getFolderUrl(dataroomName, folderId, isStaging));
    }
  };

  const goToRootFolder = (dataroom: IDataroom, isStaging: boolean = false, saveHistory: boolean = false) => {
    const rootFolderId = isStaging ? dataroom.stagingRootFolderId : dataroom.rootFolderId;
    rootFolderId && goToFolder(dataroom.name, rootFolderId, saveHistory, isStaging);
  };

  return {
    goToFolder,
    goToRootFolder,
  };
};

export default useDataroomNavigation;
