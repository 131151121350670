import React from 'react';
import FaviconDealVDR from '@/dataroom/ui/components/Landing/dealvdr/components/Favicon';
import Favicon17g5 from '@/dataroom/ui/components/Landing/17g5/Favicon';
import FaviconInvestorSet from '@/dataroom/ui/components/Landing/investorSet/Favicon';
import FaviconVeriSend from '@/dataroom/ui/components/Landing/VeriSend/Favicon';
import config from '@/dataroom/application/config/config';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';

const Favicon = () => {
  const { tenant } = useDataroomTenantContext();

  const {
    tenantDealvdr: { code: tenantDealvdr },
    tenant17g5: { code: tenant17g5 },
    tenantInvestorSet: { code: tenantInvestorSet },
    tenantVeriSend: { code: tenantVeriSend },
  } = config.tenant;

  switch (tenant) {
    case tenant17g5:
      return <Favicon17g5 />;
    case tenantInvestorSet:
      return <FaviconInvestorSet />;
    case tenantVeriSend:
      return <FaviconVeriSend />;
    case tenantDealvdr:
    default:
      return <FaviconDealVDR />;
  }
};

export default Favicon;
