import React, { Component } from 'react';
import Select, { ISelectProps } from '@/ui/shared/components/Form/Select/legacy';
import GroupedSelect from '@/ui/shared/components/Form/Select/legacy/GroupedSelect';

export interface IProps extends Partial<ISelectProps> {
  input: { [key: string]: any },
  onDidMount?: () => void,
  isGrouped?: boolean,
}

/**
 * @deprecated This component is DEPRECATED. Use ui/shared/components/Form/Fields/Select
 */
class FinalFormSelect extends Component<IProps> {
  componentDidMount() {
    if (this.props.onDidMount) {
      this.props.onDidMount();
    }
  }

  render() {
    let { input, options = [], dataTest, selectStyles, isGrouped } = this.props;

    const SelectComponent = isGrouped ? GroupedSelect : Select;

    return (
      <SelectComponent
        { ...this.props }
        name={ input.name }
        value={ input.value }
        onChange={ input.onChange }
        onBlur={ () => input.onBlur(input.value) }
        options={ options }
        dataTest={ dataTest }
        selectStyles={ selectStyles }
      />
    );
  }
}

export default FinalFormSelect;
