import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateFilesystemItemName } from '@/ui/shared/validators/validateFilesystemItemName';

// Form level validation doesn't work properly with FieldArrays.
// https://github.com/final-form/react-final-form/issues/382s

const field = { fieldName: 'name', fieldCaption: 'Folder Name' };

export default (value) => groupValidators(
  validateRequiredField({
    ...field,
    value: value?.trim(),
  }),
  validateMaxLength({
    ...field,
    length: 235,
    value: value?.trim(),
  }),
  validateFilesystemItemName({
    ...field,
    value: value?.trim(),
  }),
)?.[field.fieldName];
