import { IconType } from '@dealroadshow/uikit';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { Area } from '@/dataroom/domain/vo/Area';

export const quickFilterItems = (
  dataroomName: string,
  pathname: string,
  canUserAccessPrimary: boolean,
  canUserAccessStaging: boolean,
) => [
  {
    key: 'bookmarks',
    title: 'Bookmarks',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/bookmarks${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/bookmarks')),
    icon: IconType.bookmark,
  },
  {
    key: 'new-since-last-login',
    title: 'New since last login',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/new-since-last-login${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/new-since-last-login')),
    icon: IconType.star,
  },
  {
    key: 'date-range',
    title: 'Date range',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/date-range${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/date-range')),
    icon: IconType.calendar,
  },
  {
    key: 'unread-files',
    title: 'Unread files',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/unread-files${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/unread-files')),
    icon: IconType.notDisplay,
  },
  {
    key: 'view-only-files',
    title: 'View only files',
    path: getDataroomBasedUrl(
      dataroomName,
    `view/view-only-files${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/view-only-files')),
    icon: IconType.nonDownloaded,
  },
];
