import React, { useState } from 'react';
import cn from 'classnames';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import ChangePermissions from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import { Clearfix, responsiveModalStyles } from '@dealroadshow/uikit';
import styles from './changePermissionsSection.scss';

interface IProps {
  parentFolderId: number,
  onChange: (values: IChangePermissionsType) => void,
  isValid: boolean,
  closeModal: () => void,
  footerRefCallback: () => void,
}

const ChangePermissionsSection = (
  {
    parentFolderId,
    onChange,
    isValid,
    closeModal,
    footerRefCallback,
  }: IProps,
) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { createFolder: { isCreating } } = useFilesystemContext();

  return (
    <div className={ styles.sectionWrp }>
      <ChangePermissions
        folderId={ parentFolderId }
        onInit={ () => setIsInitialized(true) }
        onChange={ onChange }
        className={ styles.changePermissions }
      />
      <div
        className={ cn(styles.buttonsWrp, responsiveModalStyles.modalFooter) }
        ref={ footerRefCallback }
      >
        <Button
          type="submit"
          variant={ variantTypes.action }
          disabled={ !isValid || !isInitialized || isCreating }
          className={ styles.submitButton }
          title="Save Permissions & Create"
          dataTest="savePermissionsAndCreateButton"
        />
        <Button
          variant={ variantTypes.text }
          onClick={ closeModal }
          className={ styles.cancelButton }
          title="Cancel"
          dataTest="cancelSavePermissionsButton"
        />
        <Clearfix />
      </div>
    </div>
  );
};

export default ChangePermissionsSection;
