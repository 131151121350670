import dataroomConfig from '@/dataroom/application/config/config';
import env from '@/Framework/env/env';

export const domain = {
  ...dataroomConfig.domain,
  scheme: env('DOMAIN__SCHEME'),
  protocol: `${ env('DOMAIN__SCHEME') }:`,
  globalDomain: env('DOMAIN__GLOBAL'),
  apiSubdomain: env('DOMAIN__SUBDOMAIN_API'),
  socketSubdomain: env('DOMAIN__SUBDOMAIN_SOCKET'),
  usersSubdomain: env('DOMAIN__SUBDOMAIN_USERS'),
  drsSubdomain: env('DOMAIN__SUBDOMAIN_DEALROADSHOW'),
  dmPortalSubdomain: env('DOMAIN__SUBDOMAIN_DM_PORTAL'),
  condorSubdomain: env('DOMAIN__SUBDOMAIN_CONDOR'),
  openmarketSubdomain: env('DOMAIN__SUBDOMAIN_OPENMARKET'),
  drsLegacyDomain: env('DOMAIN__DRS_LEGACY_DOMAIN') || 'dealroadshow.com',
  assetsSubdomain: env('DOMAIN__SUBDOMAIN_ASSETS'),
  evercallDomain: env('EVERCALL__DOMAIN'),
  corsDomains: env('DOMAIN__CORS_DOMAINS'),
};
