import React, { useEffect } from 'react';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import cn from 'classnames';
import CountLabel from '@/dataroom/ui/common/CountLabel';
import { Spinner, Tooltip, Icon } from '@dealroadshow/uikit';
import getMenuItems from './menu';
import { useAccessRequestsCountContext } from '@/dataroom/application/accessRequests/listing/AccessRequestsCountContext';
import { AccessRequestStatus } from '@/dataroom/ui/components/Settings/ManageRequests/constants';
import styles from './settings.scss';

const Settings = () => {
  const settingItems = getMenuItems();
  const settingsList = Object.values(settingItems).filter((item) => item.isVisible);
  const { getAccessRequestsCount } = useAccessRequestsCountContext();

  const getDataTestAttributes = (str: string) => {
    const strWithoutSpaces = str.replace(/\s/g, '');
    return strWithoutSpaces.charAt(0).toLowerCase() + strWithoutSpaces.slice(1);
  };

  useEffect(() => {
    if (settingItems.manageRequests.isVisible &&
        window.location.pathname !== `${ settingItems.manageRequests.path }/${ AccessRequestStatus.Pending }`
    ) {
      getAccessRequestsCount(AccessRequestStatus.Pending);
    }
  }, []);

  return settingsList.map((item) => {
    const title = (
      <>
        <Icon
          type={ item.icon }
          className={ styles.settingsItemIcon }
        />
        <span className={ styles.settingsItemText }>{ item.title }</span>
        <CountLabel
          className={ styles.countLabel }
          totalCount={ item.count }
          canShowCount={ !item.isCountLabelFetching && !!item.count }
        />
        { item.isCountLabelFetching && <Spinner size="small" className={ styles.countLabelSpinner } /> }
      </>
    );

    if (item.isDisabled) {
      const tooltipContent = item.isAdminLite ? 'Not available based on your permissions' : `${ item.title } not available`;

      return (
        <span
          key={ item.key }
          className={ cn(styles.settingsItem, styles.isDisabled) }
          data-test={ getDataTestAttributes(item.title) }
        >
          <Tooltip
            wrpClassName={ styles.settingsItemTooltipWrapper }
            content={ tooltipContent }
          >
            { title }
          </Tooltip>
        </span>
      );
    }

    if (item.isExternal) {
      return (
        <a
          key={ item.key }
          href={ item.path }
          target="_blank"
          className={ styles.settingsItem }
          data-test={ getDataTestAttributes(item.title) }
        >
          { title }
        </a>
      );
    }

    return (
      <NextNavLink
        key={ item.key }
        to={ item.path }
        isActive={ item.isActive }
        className={ styles.settingsItem }
        activeClassName={ styles.settingsItemActive }
        data-test={ getDataTestAttributes(item.title) }
      >
        { title }
      </NextNavLink>
    );
  });
};

export default Settings;
