import React from 'react';
import { FileWithPath, fromEvent } from 'file-selector';
import { useDropzone } from 'react-dropzone';
import useRouter from '@/ui/shared/hooks/useNextRouter';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import useDataroomNavigation from '@/dataroom/application/useDataroomNavigation';
import { NotificationManager } from '@/ui/shared/components/Notification';
import readAllEntries from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/dataTranserListReader';
import Difficulty from '@/dataroom/application/FileUploader/Difficulty';
import { hasFolders, isMacOsFile, isNotMacOsFile, isValidEntry, isValidName } from './helpers';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import * as filesystemPermissions from '@/dataroom/domain/filesystemPermissions';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';

interface IProps {
  children: React.ReactNode,
}

const PROGRESS_BAR_COMPENSATION = 100;

const difficulty = new Difficulty(1, 1);

const UploadMultipleFiles = ({ children }: IProps) => {
  const { asPath: pathname } = useRouter();
  const { currentFolder } = useCurrentFolderContext();
  const { dataroom } = useDataroomContext();
  const { canUserAccessPrimary } = useCurrentUserContext();
  const { goToRootFolder } = useDataroomNavigation();
  const { tenant } = useDataroomTenantContext();
  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const {
    triggerSelectFilesAttempt,
    addFilesToUpload,
    UploaderModal,
    showUploaderModal,
    addFileSystemStructure,
    fileSystemStructure,
    resetProcessingForUpload,
    makeProcessingProgress,
    isVisibleUploaderModal,
    isUploading,
    isUploadComplete,
    setIsProcessing,
    increaseProcessingTotalDifficulty,
  } = useFileUploaderContext();
  const { isDnDFileIndexesActive } = useDnDFileIndexesContext();

  const { tenantSettingsPrefix } = DataroomTenantConfig.fromCode(tenant).options;

  const isUploadDisabledByCurrentFolder = !currentFolder || !filesystemPermissions.canUpload(currentFolder);
  const isUploadDisabledByFolderTree = !primaryFolderTree || !filesystemPermissions.canUpload(primaryFolderTree);
  const isDragAndDropDisabled = currentFolder ? isUploadDisabledByCurrentFolder : isUploadDisabledByFolderTree;

  const goToFolderIfRequired = () => {
    if (!currentFolder && !isUploadDisabledPage()) {
      goToRootFolder(dataroom, !canUserAccessPrimary, true);
    }
  };

  const onDropAccepted = (files: FileWithPath[]) => {
    const filesWithUpload = files as FileWithUploadAndPath[];
    if (!files.length) {
      return;
    }

    goToFolderIfRequired();

    if (!isUploadDisabledPage()) {
      showUploaderModal();
      addFilesToUpload(filesWithUpload);
    }
  };

  async function getFilesFromEvent(event: DragEvent) {
    const fileList = fromEvent(event);
    if (event.type === 'drop') {
      if (hasFolders(event.dataTransfer.items) && !currentFolder.canManage) {
        NotificationManager.error(getMessage(messageCodes.DATAROOM_DISABLED_FOLDER_MANAGEMENT));
        return [];
      }

      event.preventDefault();
      resetProcessingForUpload();
      setIsProcessing(true);

      goToFolderIfRequired();

      if (!isUploadDisabledPage()) {
        showUploaderModal();

        const totalCompensation = PROGRESS_BAR_COMPENSATION * difficulty.total;
        increaseProcessingTotalDifficulty(totalCompensation);

        await addFolders(event);
        await addFiles(fileList as Promise<FileWithPath[]>);

        makeProcessingProgress(totalCompensation);
        setIsProcessing(false);
      }
    } else if (event.type === 'change' || Array.isArray(event)) {
      // Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
      // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemHandle
      setIsProcessing(true);
      fileList.then((FilesWithPath: FileWithPath[]) => {
        increaseProcessingTotalDifficulty(FilesWithPath.length);
        FilesWithPath.forEach((fileWithPath) => {
          fileSystemStructure.addEntry(fileWithPath);
          makeProcessingProgress(1);
        });
      });
      addFileSystemStructure(fileSystemStructure);
      setIsProcessing(false);
    }
    return fileList as unknown as Promise<Array<File | DataTransferItem>>;
  }

  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
  } = useDropzone({
    disabled:
        isDragAndDropDisabled ||
        isUploading ||
        isUploadComplete(),
    noClick: true,
    multiple: true,
    onDropAccepted,
    // @ts-ignore
    getFilesFromEvent,
    // There is a bug in react-dropzone library https://github.com/react-dropzone/react-dropzone/issues/1079
    // After fix this Uploader troubles catcher will work more properly.
    onFileDialogCancel: triggerSelectFilesAttempt,
  });

  async function addFolders(event: DragEvent) {
    if (!event.dataTransfer?.items) {
      // If dataTransfer items is not available, we don't have access to any dragged folder.
      // It means that browser doesn't support folders uploading.
      NotificationManager.error(getMessage(messageCodes.UPLOAD_FOLDERS_UNSUPPORTED_BROWSER));
      return;
    }

    let items = await readAllEntries(
      event.dataTransfer.items,
      (folder: FileSystemDirectoryEntry) => {
        if (isMacOsFile(folder)) {
          return;
        }
        makeProcessingProgress(difficulty.read);
      }, (file: FileSystemFileEntry) => {
        if (isMacOsFile(file)) {
          return;
        }
        makeProcessingProgress(difficulty.read);
      }, (all: Array<FileSystemFileEntry | FileSystemDirectoryEntry>) => {
        increaseProcessingTotalDifficulty(
          all.filter(
            (entry) => entry && isNotMacOsFile(entry),
          ).length * difficulty.total,
        );
      },
    );

    const invalidFolders = [];
    items.directoryEntries.forEach((folder) => {
      makeProcessingProgress(difficulty.addToStructure);
      if (isMacOsFile(folder)) {
        return;
      }
      if (!isValidEntry(folder)) {
        if (!isValidName(folder.name)) {
          invalidFolders.push(folder);
        }
        return;
      }

      fileSystemStructure.addEntry(folder);
    });

    if (invalidFolders.length) {
      const foldersNames = Object.values(invalidFolders)
        .map(({ name }) => `${ name }`)
        .join(', ');

      if (invalidFolders.length > 1) {
        NotificationManager.error(getMessage(messageCodes.UPLOAD_INVALID_FOLDERS_NAMES, { foldersNames }));
      } else {
        NotificationManager.error(getMessage(messageCodes.UPLOAD_INVALID_FOLDER_NAME, { folderName: foldersNames }));
      }
    }
  }

  async function addFiles(fileList: Promise<Array<FileWithPath>>) {
    return fileList.then((FilesWithPath) => {
      FilesWithPath.forEach((fileWithPath) => {
        fileSystemStructure.addEntry(fileWithPath);
        makeProcessingProgress(difficulty.addToStructure);
      });
    });
  }

  const isUploadDisabledPage = () => isDnDFileIndexesActive ||
    pathname.includes(`settings/${ tenantSettingsPrefix }`) ||
    pathname.includes('questions/bulk-upload-questions');

  return (
    <>
      <div { ...getRootProps() }>
        { children }
        <input { ...getInputProps() } />
        { isVisibleUploaderModal && (
          <UploaderModal openFileDialog={ openFileDialog } />
        ) }
      </div>
    </>
  );
};

export default UploadMultipleFiles;
