import React from 'react';
import cn from 'classnames';
import Select from '@/ui/shared/components/Form/Select/legacy';
import styles from './selectField.scss';
import { IFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IAccessRequestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';

interface IProps<T> {
  selectClassName: string,
  options: { label: string, value: string }[],
  name: keyof Exclude<T, keyof T & `is${ string }`> & string,
  value: string | string[],
  onChange: (
    name: keyof Exclude<T, keyof T & `is${ string }`>,
    value: T[keyof Omit<T, keyof T & `is${ string }`>],
  ) => void,
  disabled: boolean,
  dataTest: string,
  isMulti?: boolean,
  error?: string,
}

const SelectField = <T = IFileUploadNotificationSettingsPayload |
  IStagingFileUploadNotificationSettingsPayload |
  IAccessRequestNotificationSettingsPayload |
  IQuestionsNotificationSettingsPayload,
>({
  selectClassName,
  options,
  name,
  value,
  onChange,
  disabled,
  dataTest,
  isMulti,
  error,
}: IProps<T>) => (
  <Select
    selectClassName={ cn(styles.select, selectClassName) }
    onChange={ (value) => onChange(name, isMulti ? value.map(({ value }) => value) : value) }
    name={ name }
    value={ isMulti ? options.filter(({ value: timeValue }) => value.includes(timeValue)) : value }
    options={ options }
    isNarrow
    simpleValue={ !isMulti }
    disabled={ disabled }
    dataTest={ dataTest }
    selectStyles={ {
      menu: (base) => Object.assign(base, { position: 'static' }),
    } }
    meta={ {
      touched: !!error,
      error,
    } }
    isMulti={ isMulti }
  />
);

export default SelectField;
