import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import { CheckAll } from '@/ui/shared/components/CheckBoxArray';
import { permissionGroupsClassNames } from '../constants';
import styles from './permissionGroupHeaderLabel.scss';

interface IProps {
  permissionGroup: {
    name: string,
    label: string,
    icon: IconType,
  },
  name: string,
  reversedArrays?: string[],
  isUnCheckEnable?: boolean,
  isCheckEnable?: boolean,
}

const PermissionGroupHeaderLabel = (props: IProps) => {
  const {
    permissionGroup,
    ...otherProps
  } = props;

  return (
    <CheckAll
      checkboxGroupName={ otherProps.name }
      { ...otherProps }
      className={ styles.headerWrp }
      label={ (
        <>
          <Icon
            type={ permissionGroup.icon }
            className={ cn(styles.headerIcon, permissionGroupsClassNames[permissionGroup.name]) }
          />
          <span className={ styles.headerLabel }>
            { permissionGroup.label }
          </span>
        </>
      ) }
      labelClassName={ cn(
        styles.headerLabelWrp,
        permissionGroupsClassNames[permissionGroup.name],
      ) }
      controlClassName={ styles.headerControl }
      dataTest={ `${ permissionGroup.name }CheckAll` }
    />
  );
};

export default PermissionGroupHeaderLabel;
