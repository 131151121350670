import dynamic from 'next/dynamic';

import getSupportChatId from '@/ui/shared/helpers/getSupportChatId';
import Logger from '@/Framework/browser/log/Logger';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';
import { useDIContext } from '@/Framework/DI/DIContext';

const DynamicScript = dynamic(() => import('@/ui/shared/components/Landing/ContactSupport/DynamicScript'), {
  ssr: false,
});

interface IProps {
  children: React.ReactNode,
}

const ContactSupport = (props: IProps) => {
  const { container } = useDIContext();

  // @ts-ignore
  const onClick = () => window.FrontChat('show');

  const handleScriptLoad = () => {
    try {
      const cookieRepository = container.get(CookieRepository);
      // removing cookie as requested from business @amorgunov
      cookieRepository.removeFromCurrentDomain('fcpmuc');
      const chatId = getSupportChatId();
      // @ts-ignore
      window.FrontChat('init', {
        chatId,
        useDefaultLauncher: false,
      });
      // @ts-ignore
      window.FrontChat('onUnreadChange', onClick);
    } catch (e) {
      Logger.error(e);
    }
  };

  return (
    <>
      <DynamicScript
        src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
        onLoad={ handleScriptLoad }
      />
      <div
        onClick={ onClick }
        data-test="contactSupportButton"
      >
        { props.children }
      </div>
    </>
  );
};

export default ContactSupport;
