import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';

@Dependencies(JsonRpcDispatcherFactory)
class ConsentRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {
  }

  acceptConsent = async (tenant: DataroomTenant): Promise<void> => {
    const request = new Request('consent.accept');
    await this.rpc(tenant).call<RpcSuccess>(request);
  };

  isConsentAcceptedById = async (tenant: DataroomTenant, contactId: number): Promise<{ accepted: boolean }> => {
    const request = new Request('consent.is_accepted_by_contact_id', { contactId });
    const response = await this.rpc(tenant).call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  isConsentAccepted = async (tenant: DataroomTenant): Promise<{ accepted: boolean }> => {
    const request = new Request('consent.is_accepted');
    const response = await this.rpc(tenant).call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default ConsentRepository;
