import React, { useEffect } from 'react';
import { Store as ReduxStore } from 'redux';
import fetchIntercept from 'fetch-intercept';
import TwoFactorAuthentication from '@/users/ui/components/TwoFactorAuthentication';
import { initAutoDownload } from '@/Framework/Bootstrap/initAutoDownload';
import { GoogleAnalytics, GoogleTagManager } from '@/Framework/browser/analytics/google';
import GlobalErrorContext from '@/Framework/ErrorHandling/GlobalError/GlobalErrorContext';

import SessionContext from '@/users/application/Session/SessionContext';
import { InitNotifications } from '@/ui/shared/components/Notification';
import registerMessagesExecCallback from '@/Framework/Message/registerMessagesExecCallback';
import DIContext from '@/Framework/DI/DIContext';
import Container from '@/Framework/DI/Container';
import { checkSession } from '@/users/application/Session/interceptors/fetch/checkSession';
import { useHistory } from 'react-router';
import Store from '@/Framework/DI/Providers/Redux/Store';
import { History } from '@/Framework/DI/Providers/History';
import { InitSessionState } from '@/users/application/Session/serverSide/InitSessionState';
import { ISession } from '@/users/domain/session/vo/Session';
import { IUser } from '@/users/domain/vo/User';
import ClientCookie from '@/Framework/Router/Next/cookie/ClientCookie';
import ClientReqData from '@/Framework/Router/Next/headers/ClientReqData';

interface IProps {
  container: Container,
  store?: ReduxStore,
  session: {
    initialized: boolean,
    currentSession: ISession,
    currentUser: IUser,
    currentHost: string,
    fingerprint: string,
    userAgent: string,
  },
  children: React.ReactNode,
  cookies: { [key: string]: any },
}

function Bootstrap({ session, children, container, store, cookies }: IProps) {
  container.bindValue(ClientCookie, new ClientCookie(cookies));
  container.bindValue(ClientReqData, new ClientReqData({
    userAgent: session?.userAgent,
    fingerprint: session?.fingerprint,
    currentHost: session?.currentHost,
  }));
  container.bindValue(InitSessionState, session);

  const history = useHistory();
  useEffect(() => {
    if (store) {
      container.bindValue(Store, store);
    }
    container.bindValue(History, history);

    // @ts-ignore
    fetchIntercept.register({ response: checkSession(container) });
    initAutoDownload(history);
    registerMessagesExecCallback(container);
  }, []);

  return (
    <DIContext container={ container }>
      <GoogleAnalytics />
      <GoogleTagManager />
      <InitNotifications />
      <SessionContext>
        <GlobalErrorContext history={ history }>
          <TwoFactorAuthentication>
            { children }
          </TwoFactorAuthentication>
        </GlobalErrorContext>
      </SessionContext>
    </DIContext>
  );
}

export default Bootstrap;
