import React from 'react';
import cn from 'classnames';
import SyntheticEventEmitter from '@/Framework/dataHelpers/event/SyntheticEventEmitter';
import { contextMenuId } from '../constants';
import { generateEventType } from '../helpers';

interface IProps {
  id?: string,
  collect?: () => {},
  className?: string,
  children?: React.ReactNode | Array<React.ReactNode>,
}

const ContextMenuTrigger = ({
  id = contextMenuId,
  collect,
  className,
  children,
}: IProps) => {
  const handleContextMenuShow = (e) => {
    e.preventDefault();
    e.stopPropagation();

    SyntheticEventEmitter.emit(generateEventType(id), {
      detail: {
        data: collect(),
        x: e.clientX,
        y: e.clientY,
      },
    });
  };

  return (
    <div
      className={ cn('react-contextmenu-wrapper', className) }
      onContextMenu={ handleContextMenuShow }
    >
      { children }
    </div>
  );
};
export default ContextMenuTrigger;
