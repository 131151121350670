import React from 'react';
import HeaderBannerContext from '@/ui/shared/components/Layout/HeaderBanner/HeaderBannerContext';
import CookiesBannerContext from '@/ui/shared/components/Landing/CookiesBanner/CookiesBannerContext';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import FeatureToggleContextProvider from '@/ui/shared/modules/FeatureToggle/Context';

interface IProps extends IGlobalProps {
  children: React.ReactNode,
}

const GlobalProps = ({ children, banner, cookiesBanner, featuresConfig }: IProps) => (
  <HeaderBannerContext value={ banner }>
    <CookiesBannerContext value={ cookiesBanner }>
      <FeatureToggleContextProvider featuresConfig={ featuresConfig }>
        { children }
      </FeatureToggleContextProvider>
    </CookiesBannerContext>
  </HeaderBannerContext>
);

export default GlobalProps;
