import React from 'react';

import QuestionFields from './QuestionFields';
import { ICategory } from '@/dataroom/domain/questions/vo/Category';
import { IQuestionPriorities } from '@/dataroom/domain/questions/vo/QuestionPriorities';
import { IFinalFormApi } from '@/ui/shared/components/Form/FinalForm/interfaces';

interface IProps {
  fieldArrayComponent: IFinalFormApi['FieldArray'],
  fieldComponent: IFinalFormApi['Field'],
  categoriesCollection: ICategory[],
  activePriority?: string,
  priorityRemain?: IQuestionPriorities,
  isAllLimitsReached?: boolean,
  initialPriority?: string,
  isMaxQuantity?: boolean,
}

const QuestionsBlock = ({
  fieldArrayComponent: FieldArray,
  fieldComponent: Field,
  categoriesCollection,
  activePriority,
  priorityRemain,
  initialPriority,
  isAllLimitsReached,
  isMaxQuantity,
}: IProps) => (
  <div>
    <FieldArray name="questions">
      { ({ fields }) => (
        <QuestionFields
          fields={ fields }
          fieldComponent={ Field }
          categoriesCollection={ categoriesCollection }
          activePriority={ activePriority }
          priorityRemain={ priorityRemain }
          isAllLimitsReached={ isAllLimitsReached }
          initialPriority={ initialPriority }
          isMaxQuantity={ isMaxQuantity }
        />
      ) }
    </FieldArray>
  </div>
);

export default QuestionsBlock;
