import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import FileRepository from '@/dataroom/infrastructure/repository/FileRepository';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { isFolder } from '@/dataroom/domain/filesystem';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { FileIndexShiftMethod } from '@/dataroom/domain/vo/filesystem/FileIndexShiftMethod';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

const useFileIndexChange = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [conflictItemName, setConflictItemName] = useState<string>(null);

  const { dataroom } = useDataroomContext();
  const { currentFolder } = useCurrentFolderContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const { updateFolderTree } = useFolderTreeContext();

  const changeIndex = async (
    item: IFilesystemListItem | IFolderTree,
    index: number,
    overrideMethod?: FileIndexShiftMethod,
  ) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        index,
        ...(overrideMethod ? { overrideMethod } : null),
      };

      if (isFolder(item)) {
        await container.get<FolderRepository>(FolderRepository).changeIndex({
          ...payload,
          folderId: item.id,
        });
      } else {
        await container.get<FileRepository>(FileRepository).changeIndex({
          ...payload,
          fileId: item.id,
        });
      }

      NotificationManager.success(getMessage(messageCodes.DATAROOM_INDEX_UPDATE_SUCCESS));

      currentFolder && updateDataroomExplorerCollection();

      if (isFolder(item)) {
        updateFolderTree(item.id);
      }
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const changeIndexOverrideAndShiftAll = (
    item: IFilesystemListItem | IFolderTree,
    index: number,
  ) => changeIndex(item, index, FileIndexShiftMethod.All);

  const changeIndexOverrideAndShiftNonPinned = (
    item: IFilesystemListItem | IFolderTree,
    index: number,
  ) => changeIndex(item, index, FileIndexShiftMethod.Common);

  const unpinIndex = async (item: IFilesystemListItem | IFolderTree) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
      };

      if (isFolder(item)) {
        await container.get<FolderRepository>(FolderRepository).unpin({
          ...payload,
          folderId: item.id,
        });
      } else {
        await container.get<FileRepository>(FileRepository).unpin({
          ...payload,
          fileId: item.id,
        });
      }

      NotificationManager.success(getMessage(messageCodes.DATAROOM_INDEX_UNPIN_SUCCESS));

      currentFolder && updateDataroomExplorerCollection();

      if (isFolder(item)) {
        updateFolderTree(item.id);
      }
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const checkCustomExists = async (item: IFilesystemListItem | IFolderTree, index: number) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        index,
      };

      const { name: conflictItemName = null, exist: isCustomExists } = isFolder(item)
        ? await container.get<FolderRepository>(FolderRepository).getNameWithCustomIndex({
          ...payload,
          folderId: item.id,
        })
        : await container.get<FileRepository>(FileRepository).getNameWithCustomIndex({
          ...payload,
          fileId: item.id,
        });

      setConflictItemName(conflictItemName);

      return {
        isCustomExists,
        hasError: false,
      };
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
      return {
        isCustomExists: false,
        hasError: true,
      };
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    conflictItemName,
    changeIndex,
    changeIndexOverrideAndShiftAll,
    changeIndexOverrideAndShiftNonPinned,
    unpinIndex,
    checkCustomExists,
  };
};

export default useFileIndexChange;
