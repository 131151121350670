import React from 'react';
import { isFolder, isUrl } from '@/dataroom/domain/filesystem';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { addHttpToUrl } from '@/Framework/url/helpers';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';

interface IProps {
  item: IFilesystemListItem,
  children: (args: { onClick: any }) => React.ReactElement,
  search?: string,
}

const PreviewButtonHandler = ({ item, search, children }: IProps) => {
  const {
    previewFile,
    trackLinkPreviewAnalytics,
  } = useDocumentPreviewContext();

  const handlePreviewClick = async () => {
    if (isFolder(item)) {
      return;
    }

    if (isUrl(item)) {
      trackLinkPreviewAnalytics(item);
      window.open(addHttpToUrl(item.href), '_blank');
      return;
    }

    await previewFile(item, search);
  };

  return children({
    onClick: handlePreviewClick,
  });
};

export default PreviewButtonHandler;
