import DataScrubber, { Config } from '@finsight/data-scrubber';
import config from '@/Framework/config';
import scrubRoute from '@/Framework/browser/log/sentry/scrubRoute';
import isServer from '@/Framework/Router/Next/isServer';

const scrubSensitiveDataFactory = () => {
  let rawConfig = '';
  try {
    rawConfig = isServer()
      ? Buffer.from(config.log.dataScrubber, 'base64').toString('utf-8')
      : atob(config.log.dataScrubber);
  } catch (e) { /* */ }

  const scrubberConfig = Config.fromJson(rawConfig);
  const dataScrubber = new DataScrubber(scrubberConfig);

  return (event) => {
    event = dataScrubber.scrub(event);
    if (event.request?.url) {
      event.request.url = scrubRoute(event.request.url);
    }
    if (event.request?.headers?.Referer) {
      event.request.headers.Referer = scrubRoute(event.request.headers.Referer);
    }

    return event;
  };
};

const scrubSensitiveData = scrubSensitiveDataFactory();

export default scrubSensitiveData;
