import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import { IDataroomRegion } from '@/dataroom/domain/vo/DataroomRegion';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { IDataroomListItem } from '@/dataroom/domain/vo/collection/DataroomListItem';
import { IFetchCollectionPayload, IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

@Dependencies(JsonRpcDispatcherFactory)
class DataroomRepository {
  protected constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
  }

  getSettings = async (
    payload: {
      filters: {
        tenant: DataroomTenant,
        dataroomId: number,
      },
    },
  ): Promise<{
    timeZone: ITimezone,
    totalFilesCount: number,
    createdAt: string,
    deactivatedAt: string,
    dataroomName: string,
  }> => {
    const request = new Request('dataroom.get_settings', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getList = async (
    payload: IFetchCollectionPayload,
  ): Promise<IFetchCollectionResponse<IDataroomListItem>> => {
    if (payload.perPage === 'all') {
      const { perPage, page, ...otherPayload } = payload;
      payload = otherPayload;
    }

    payload = {
      sortBy: 'name',
      sortOrder: 'asc',
      ...payload,
    };

    const request = new Request('dataroom.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  create = async (
    payload: {
      name: string,
      displayName: string,
      fileStorageRegion: number,
      transactionTypeId: string,
      stagingEnabled: number,
      disclaimer?: string,
      publicity?: number,
      sponsor?: {
        name: string,
      },
      countryId?: string,
      subsectorId?: string,
      subindustryId?: string,
      entryCode?: number,
      planId?: number,
      logoHash?: string,
      docState?: number,
    }): Promise<{ id: number }> => {
    const request = new Request('dataroom.create', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  isUrlFree = async (
    payload: {
      search: string,
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.name.exists', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  getDataroomById = async (payload: { dataroomId: number }): Promise<IDataroom> => {
    const request = new Request('dataroom.get_by_id', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getDataroomByName = async (payload: { dataroomName: string }): Promise<IDataroom> => {
    const request = new Request('dataroom.get_by_name', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  deactivate = async (
    payload: {
      dataroomId: number,
      prepare: string[],
      truncationMode?: string,
      stagingTruncationMode?: string,
      hash: string,
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.deactivate', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  editSettings = async (
    payload: {
      dataroomId: number,
      displayName: string,
      disclaimer: string,
      publicity: number,
      emailUploading: boolean,
      audioUploading: boolean,
      watermarking: boolean,
      stagingWatermarking: boolean,
      qnaModule?: boolean,
      qnaModuleDefaultShareEnabled?: boolean,
      timeZoneId: string,
      fileIndexing: boolean,
      fileIndexOrder?: string,
      stagingFileIndexing?: boolean,
      stagingFileIndexOrder?: string,
      stagingEnabled: number,
      sponsor: {
        id: number,
        name: string,
      },
      logoHash: string,
      logoName: string,
      underwriters: {
        name: string,
        lead: boolean,
      }[],
      currencyId: number,
      countryId: number,
      subsectorId: number,
      subindustryId: number,
      transactionTypeId: string,
      docState?: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.edit_general', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  canFollow = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.can_follow', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult() as any;
  };

  canRequestAccess = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<{ allowance: 'accessDenied' | 'requestAllowed' }> => {
    const request = new Request('dataroom.can_request_access', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRegionList = async (): Promise<IDataroomRegion[]> => {
    const request = new Request('dataroom.get_file_storage_regions');
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default DataroomRepository;
