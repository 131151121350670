import React from 'react';
import cn from 'classnames';
import * as column from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/constants';
import ItemUploadStatus
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/Columns/ItemUploadStatus';
import Name from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/Columns/Name';
import Path from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/Columns/Path';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from '../uploaderModal.scss';

export default (isUploading: boolean, isUploadComplete: boolean) => [
  {
    name: column.NAME,
    title: 'File name',
    width: '43%',
    sortable: true,
    className: cn(alignStyles.alignLeft, styles.fileNameCol),
    cellCallback: (cellProps) => (
      <Name cellProps={ cellProps } />
    ),
  },
  {
    name: column.PATH,
    title: 'Path',
    width: '39%',
    sortable: true,
    className: styles.pathCol,
    cellCallback: (cellProps) => (
      <Path cellProps={ cellProps } />
    ),
  },
  {
    name: column.STATUS,
    title: 'status',
    width: '18%',
    sortable: isUploading || isUploadComplete,
    className: alignStyles.alignRight,
    cellCallback: (cellProps) => (
      <ItemUploadStatus
        cellProps={ cellProps }
      />
    ),
  },
];
