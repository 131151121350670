import React from 'react';
import cn from 'classnames';
import ContactSupport from '@/ui/shared/components/Landing/ContactSupport';
import headphonesIcon from './headphones.inline.svg';
import { Tooltip } from '@dealroadshow/uikit';
import footerStyles from '@/dataroom/ui/components/Dataroom/Sidebar/Footer/footer.scss';
import styles from './supportChat.scss';

function SupportChat() {
  const action = (
    <span
      className={ cn(styles.supportChatIcon, footerStyles.leftAlignedAction) }
      dangerouslySetInnerHTML={ { __html: headphonesIcon } }
    />
  );

  return (
    <div className={ footerStyles.leftAlignedIcon }>
      <ContactSupport>
        <Tooltip
          content="Live Tech Support"
          placement="top"
        >
          { action }
        </Tooltip>
      </ContactSupport>
    </div>
  );
}

export default SupportChat;
