import React from 'react';
import FilesystemIcon from '@/dataroom/ui/common/FilesystemIcon';
import { isFolder, isUrl } from '@/dataroom/domain/filesystem';

import styles
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DragAndDropModal/DragAndDropTable/dragAndDropTable.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

export default [
  {
    name: 'fileIndexFull',
    width: 60,
    cellCallback: (cellProps) => (
      <span className={ styles.fileIndexCell }>{ cellProps.cellData }</span>
    ),
  },
  {
    name: 'name',
    cellCallback: (cellProps) => (
      <span className={ styles.nameCell }>
        <FilesystemIcon
          extension={ cellProps.row.extension }
          type={ cellProps.row.type }
          className={ styles.filesystemIcon }
          isStaging={ cellProps.row.isStaging }
        />
        { cellProps.cellData }
      </span>
    ),
  },
  {
    name: 'canMove',
    className: alignStyles.alignRight,
    cellCallback: ({ row }) => {
      if (row.canMove) return '';

      let title = isFolder(row) ? 'folder' : 'file';
      if (isUrl(row)) title = 'link';

      return (
        <span className={ styles.canMoveCell }>
          { `You do not have permission to move this ${ title }` }
        </span>
      );
    },
  },
];
