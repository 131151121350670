import React from 'react';
import questionIcon from './question.inline.svg';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { Tooltip } from '@dealroadshow/uikit';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import styles from '@/dataroom/ui/components/Dataroom/Sidebar/Footer/footer.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

function Support() {
  const { tenant } = useDataroomTenantContext();
  const { name } = DataroomTenantConfig.fromCode(tenant);

  const action = (
    <a
      href={ dataroomUrl(tenant).getHelpUrl() }
      target="_blank"
      className={ styles.leftAlignedAction }
      data-test="visitSupportSiteButton"
    >
      <span dangerouslySetInnerHTML={ { __html: questionIcon } } />
    </a>
  );

  return (
    <div className={ styles.leftAlignedIcon }>
      <Tooltip
        content={ `Visit the ${ name } Support Site` }
        placement="top"
      >
        { action }
      </Tooltip>
    </div>
  );
}

export default Support;
