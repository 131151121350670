import React, { ReactElement } from 'react';
import isEmpty from 'lodash/isEmpty';
import { splitItems } from '@/dataroom/domain/filesystem';
import { Spinner, responsiveModalStyles } from '@dealroadshow/uikit';
import FilesystemIcon from '@/dataroom/ui/common/FilesystemIcon';
import Size from '@/dataroom/ui/common/DataroomExplorer/Columns/Size';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import styles from './deleteModalForm.scss';
import { IPermissionGroup } from '@/dataroom/domain/vo/permissionGroups/manage/PermissionGroup';

interface IProps {
  disclaimer: string | ReactElement,
  items: IFilesystemListItem[],
  isFileSystemDeleteModal: boolean,
  isFetching: boolean,
  closeModal?: () => void,
  onSubmit: (items: IFilesystemListItem[] | IPermissionGroup[]) => Promise<void>,
  footerRefCallback?: (ref: HTMLDivElement) => void,
}

const DeleteFileSystemItemsForm = ({
  disclaimer,
  items,
  isFileSystemDeleteModal = true,
  isFetching,
  closeModal,
  onSubmit,
  footerRefCallback,
}: IProps) => {
  const isPlural = items.length > 1;
  const defaultDisclaimer = (
    <div className={ styles.disclaimerWrp }>
      Please confirm you would like to{ ' ' }
      <span className={ styles.disclaimerBold }>Delete</span>{ ' ' }
      the selected item{ isPlural ? 's' : '' }. Deleting { isPlural ? 'these items' : 'this item' } will also remove{ ' ' }
      { isPlural ? 'them' : 'it' } from analytics.
      Alternatively, you can retain analytics data by adjusting user permissions.
    </div>
  );

  const handleDelete = () => onSubmit(items).then(closeModal);

  const getSortedItems = () => {
    // @ts-ignore
    const { folders, files } = splitItems(items);
    const sortFunction = (a, b) => (a.name > b.name ? 1 : -1);

    folders.sort(sortFunction);
    files.sort(sortFunction);

    return folders.concat(files);
  };

  return (
    <>
      <div className={ styles.contentWrp }>
        { disclaimer || defaultDisclaimer }
        <div className={ styles.deleteListWrp }>
          { getSortedItems().map((item: IFilesystemListItem) => (
            <div
              key={ item.id }
              className={ styles.deleteItem }
            >
              <div>
                { isFileSystemDeleteModal && (
                  <FilesystemIcon
                    isOpen={ false }
                    extension={ item.extension }
                    type={ item.type }
                    isStaging={ item.isStaging }
                    className={ styles.fileSystemIcon }
                  />
                ) }
                { item.name }
              </div>
              { isFileSystemDeleteModal && (
                <div className={ styles.sizeColumn }>
                  <Size value={ item.size } />
                </div>
              ) }
            </div>
          )) }
        </div>
        <Spinner
          overlay
          isVisible={ isFetching }
        />
      </div>
      <div
        className={ responsiveModalStyles.modalFooter }
        ref={ footerRefCallback }
      >
        <Button
          className={ styles.submitButton }
          variant={ variantTypes.action }
          disabled={ isEmpty(items) || isFetching }
          onClick={ handleDelete }
          title="Delete"
          dataTest="dataroomExplorerDeleteButton"
        />
        <Button
          variant={ variantTypes.text }
          onClick={ closeModal }
          title="Cancel"
          dataTest="dataroomExplorerCancelButton"
        />
      </div>
    </>
  );
};

export default DeleteFileSystemItemsForm;
