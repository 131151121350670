import React, { useState, useEffect } from 'react';
import BaseCheckboxArray from './BaseCheckboxArray';
import BaseCheckAll from './BaseCheckAll';
import { defaultCheckboxGroupName } from '@/ui/shared/components/CheckBoxArray/constants';

const CheckboxArray = (props) => (
  <BaseCheckboxArray { ...props } />
);

const CheckAll = (props) => (
  <BaseCheckAll { ...props } />
);

export default function useCheckboxArray<T>(initialValues = {}) {
  const [values, setValues] = useState(initialValues);

  const change = (name, value) => {
    const newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const reset = () => {
    setValues(initialValues);
  };

  useEffect(() => { setValues(initialValues); }, [initialValues]);

  return {
    CheckAll,
    CheckboxArray,
    change,
    reset,
    values,
    setValues,
    selected: values[defaultCheckboxGroupName] as Array<T> || [], // for convenience
  };
}
