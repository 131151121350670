export const COMPANY_NAME_MIN_LENGTH = 3;
export const COMPANY_NAME_MAX_LENGTH = 128;

/**
 * @param {Array} companies
 * @param {number} id
 * @return {string}
 */
export function getCompanyNameById(companies, id) {
  const company = companies.find((company) => company.id === id);
  return company ? company.name : null;
}

/**
 * @param {Array} companies
 * @param {string} name
 * @return {String|null}
 */
export function getCompanyIdByName(companies, name) {
  const company = companies.find((company) => company.name === name);
  return company ? company.id : null;
}

/**
 * @param {Array} companies
 * @param {String} name
 * @return {Object}
 */
export function findCompanyByName(companies, name) {
  return companies.find((company) => company.name === name);
}
