export const popperModifiers = [
  {
    name: 'popperSize',
    enabled: true,
    phase: 'main',
    fn: (data) => {
      data.state.rects.popper.width = data.state.rects.reference.width;
      data.state.elements.popper.style.width = `${ data.state.rects.reference.width }px`;
    },
  },
  {
    name: 'flip',
    enabled: false,
  },
];
