import React, { Component } from 'react';
import cn from 'classnames';
import isObject from 'lodash/isObject';
import ReactPhoneInput from 'react-phone-input-2';
import { Manager, Popper, Reference } from 'react-popper';
import startsWith from 'lodash/startsWith';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import { FormError, FormField, InputLabel as Label, Icon, IconType } from '@dealroadshow/uikit';
import PortalWrp from '@/ui/shared/components/Layout/PortalWrp';

import inputStyles from '../input.scss';
import styles from './phone.scss';

import { popperModifiers } from './constants';

// Workaround according to issue in v2.15.1 (React v18): https://github.com/bl00mber/react-phone-input-2/issues/533
// @ts-ignore
const PhoneInput = ReactPhoneInput.default ? ReactPhoneInput.default : ReactPhoneInput;

interface IProps {
  isFinalForm?: boolean,
  input: any,
  meta: any,
  name: string,
  label: string,
  placeholder: string,
  disabled?: boolean,
  isErrorMessage?: boolean,
  isNarrow?: boolean,
  defaultCountry?: string,
  prefix?: string,
  value: string | number,
  usePortal?: boolean,
  portalId: string,
  onChange?: (payload: any) => void,
  onBlur?: () => void,
  onFocus?: () => void,
  isClearable?: boolean,
  formFieldClassName: string,
  isSmall?: boolean,
  dataTest: string,
}

const defaultProps = {
  isFinalForm: false,
  disabled: false,
  isErrorMessage: true,
  isNarrow: false,
  isClearable: true,
  defaultCountry: 'us',
  prefix: '+',
  usePortal: false,
  isSmall: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

class Phone extends Component<IProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      isHovered: false,
      isChanged: false,
      value: '',
      countryData: null,
      defaultCountryData: null,
    };

    this.phoneInputRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      defaultCountryData: this.phoneInputRef?.state?.onlyCountries
        .find((country) => country.iso2 === this.props.defaultCountry),
    });
    this.updateFormattedNumber();
    this.overridePopperStyleCountryDropdownList();
    this.overrideClickOutsideHandler();
  };

  componentDidUpdate = () => {
    this.updateFormattedNumber();
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.phoneInputRef?.handleClickOutside);
  };

  private phoneInputRef;

  private nodeInput;

  /* TODO: @vladimirlomonos please refactor this code. */
  updateFormattedNumber = () => {
    const { isChanged, countryData, defaultCountryData } = this.state;
    let changeValue = isChanged
      ? this.state.value
      : this.state.value || this.value();
    // @ts-ignore
    let value = (isObject(changeValue)) ? changeValue.value : changeValue;
    const phoneInput = this.phoneInputRef;
    const selectedCountry = (value && startsWith(value, this.props.prefix))
      ? phoneInput.guessSelectedCountry(
        value.replace(/\D/g, '').substring(0, 6),
        phoneInput.state.country,
        phoneInput.state.onlyCountries,
        phoneInput.state.hiddenAreaCodes,
      )
      : null;
    const dialCode = countryData?.dialCode ||
                     selectedCountry?.dialCode ||
                     phoneInput.getCountryData().dialCode ||
                     defaultCountryData?.dialCode;

    if (isString(value) && !startsWith(value, this.props.prefix) && !startsWith(value, dialCode)) {
      phoneInput.setState({
        formattedNumber: `${ this.props.prefix }${ isChanged ? value : value || dialCode }`,
        country: this.props.defaultCountry,
        selectedCountry: defaultCountryData,
      });
    } else if (value) {
      value = value.replace(/\D/g, '');

      let newSelectedCountry = phoneInput.guessSelectedCountry(
        value.substring(0, 6),
        this.props.defaultCountry,
        [],
        [],
      );

      if (!newSelectedCountry) {
        newSelectedCountry = phoneInput.state.selectedCountry;
      }
      value = newSelectedCountry ? phoneInput.formatNumber(value, newSelectedCountry) : value;
      phoneInput.setState({ formattedNumber: value, selectedCountry: newSelectedCountry });
    }

    changeValue = (this.isValueTheSameAsInitial(value)) ? this.props.meta.initial : {};

    changeValue.value = value === this.props.prefix ? '' : value;
    changeValue.code = this.props.prefix + dialCode;

    if (this.props.isFinalForm) {
      /*
      * If you try to use Phone inside Final Form, you might see an infinite loop.
      * Probably, the issue occurs because of the way, how the new and the old value are being compared inside onChange.
      * Redux-form has lodash lib as a dependency, while final-form doesn't...
      *  */
      if (isEqual(changeValue, this.value())) {
        return;
      }
      this.props.input.onChange(changeValue);
      return;
    }

    if (this.props.input && ('onChange' in this.props.input)) {
      this.props.input.onChange(changeValue);
      return;
    }

    this.props.onChange({
      target: {
        value: changeValue,
      },
    });
  };

  isValueTheSameAsInitial = (value) => value?.replace(/[^+\d]/g, '') === this.props.meta.initial?.value?.replace(/[^+\d]/g, '');

  value = () => {
    if (this.props.input && ('value' in this.props.input)) {
      return this.props.input.value;
    }
    return this.props.value;
  };

  /**
   * @param {SyntheticEvent|string} event
   * @param {object} countryData
   * @return {String}
   */
  handleOnChange = (event, countryData) => {
    const value = ((event instanceof Object) ? event.target.value : event);
    const { dialCode } = countryData;

    this.setState({
      value: (value && startsWith(value.replace(this.props.prefix, ''), dialCode))
        ? this.phoneInputRef.state.formattedNumber
        : value,
      countryData,
      isChanged: true,
    });
  };

  onFocus = () => {
    this.setState({
      isFocused: true,
    });
    if (this.props.input && ('onFocus' in this.props.input)) {
      return this.props.input.onFocus();
    }
    if (this.nodeInput.current) {
      this.nodeInput.current.focus();
    }

    return this.props.onFocus();
  };

  handleOnBlur = () => {
    if (!this.state.isHovered) {
      this.setState({
        isFocused: false,
      });
    }

    if (this.props.input && ('onBlur' in this.props.input)) {
      return this.props.input.onBlur();
    }
    return this.props.onBlur();
  };

  overridePopperStyleCountryDropdownList = () => {
    if (!this.phoneInputRef) {
      return;
    }

    const { usePortal, portalId } = this.props;

    this.phoneInputRef.getCountryDropdownList = ((nativeCountryDropdownList) => () => (
      <PortalWrp
        usePortal={ usePortal }
        portalId={ portalId }
      >
        <Popper
          placement="bottom-start"
          // @ts-ignore
          modifiers={ popperModifiers }
        >
          { ({ placement, ref, style }) => (
            <div
              ref={ ref }
              style={ style }
              data-placement={ placement }
              className={ styles.phoneDropdownWrp }
            >
              { nativeCountryDropdownList() }
            </div>
          ) }
        </Popper>
      </PortalWrp>
    ))(this.phoneInputRef.getCountryDropdownList);
  };

  overrideClickOutsideHandler = () => {
    if (!this.phoneInputRef) {
      return;
    }

    this.phoneInputRef.handleClickOutside = ((nativeHandleClickOutside) => {
      document.removeEventListener('mousedown', nativeHandleClickOutside);

      return (e) => {
        if (e?.target?.parentElement.className !== 'country') {
          nativeHandleClickOutside(e);
        }
      };
    })(this.phoneInputRef.handleClickOutside);

    document.addEventListener('mousedown', this.phoneInputRef.handleClickOutside);
  };

  handleHoverOn = () => {
    this.setState({ isHovered: true });
  };

  handleHoverOff = () => {
    this.setState({ isHovered: false });
  };

  onClearButtonClick = () => {
    this.setState({
      value: this.phoneInputRef.getCountryData().dialCode,
    });
  };

  renderClearButton = () => {
    const {
      isClearable,
      disabled,
      isSmall,
    } = this.props;

    if (isClearable) {
      return (
        <div className={ cn(styles.clearButtonWrp, { [styles.small]: isSmall }) }>
          { (!disabled &&
             isClearable &&
             (this.state.isHovered || this.state.isFocused) &&
             (this.state.value?.length > 4 || this.props.input.value?.value?.length > 4))
            ? (
              <div
                className={ cn(styles.clearableBtn, { [styles.small]: isSmall }) }
                onClick={ this.onClearButtonClick }
              >
                <Icon type={ IconType.close } />
              </div>
            )
            : null }
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      input,
      meta,
      label,
      disabled,
      placeholder,
      isErrorMessage,
      isNarrow,
      defaultCountry,
      prefix,
      dataTest,
      formFieldClassName,
      isSmall,
    } = this.props;

    const inputProps = {
      name: input.name,
    };

    return (
      <FormField
        isNarrow={ isNarrow }
        isValidationFeedback={ !!(meta && meta.touched && meta.error) }
        dataTest={ dataTest }
        className={ formFieldClassName }
      >
        { label && (
          <Label htmlFor={ input.name }>
            { label }
          </Label>
        ) }
        <Manager>
          <Reference>
            { ({ ref }) => (
              <div
                ref={ ref }
                className={ styles.phoneWrp }
                onMouseOver={ this.handleHoverOn }
                onMouseLeave={ this.handleHoverOff }
                data-test="phoneInput"
              >
                <PhoneInput
                  ref={ (ref) => { this.phoneInputRef = ref; } }
                  tabIndex="-1"
                  disabled={ disabled }
                  inputProps={ { ...inputProps } }
                  containerClass={ cn(styles.phoneContainer, { [styles.small]: isSmall }) }
                  inputClass={ cn(styles.phoneInput, inputStyles.formInput, { [inputStyles.small]: isSmall }) }
                  buttonClass={ styles.phoneButton }
                  dropdownClass={ styles.phoneDropdown }
                  country={ defaultCountry }
                  placeholder={ placeholder }
                  jumpCursorToEnd={ false }
                  onChange={ this.handleOnChange }
                  onBlur={ this.handleOnBlur }
                  onFocus={ this.onFocus }
                  value={ this.state.value }
                  copyNumbersOnly={ false }
                  prefix={ prefix }
                  specialLabel=""
                  enableClickOutside={ false }
                  isValid={ () => meta.valid || !meta.touched }
                  localization={ { Turkey: 'Türkiye' } }
                />
                { this.renderClearButton() }
              </div>
            ) }
          </Reference>
        </Manager>
        { isErrorMessage && <FormError { ...meta } /> }
      </FormField>
    );
  }
}

// @ts-ignore
Phone.defaultProps = defaultProps;

export default Phone;
