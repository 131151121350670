import { useState } from 'react';
import cn from 'classnames';
import Clipboard from 'react-clipboard.js';
import noop from 'lodash/noop';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import linkStyles from '@/ui/shared/styles/links.scss';
import styles from './copyToClipboardButton.scss';

interface IProps {
  content: string,
  dataTest?: string,
  isOutlineButtons?: boolean,
  className?: string,
  copyElemClassName?: string,
  copiedElemClassName?: string,
  labelBeforeCopy?: string,
  labelAfterCopy?: string,
  isDisabled?: boolean,
  isMobile?: boolean,
  customStyles?: string,
  withTooltip?: boolean,
  copyTooltip?: React.ReactNode,
  copiedTooltip?: React.ReactNode,
  handleCopy?: () => void,
  copiedMessageDelay?: number,
  iconType?: IconType,
  hideOnTooltipMouseEnter?: boolean,
  popperModifiers?: any[],
  copiedIconType?: IconType,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * Note: CopyToClipboardButton should be merged with CopyToClipboard, before moving
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const CopyToClipboardButton = ({
  dataTest = 'copyToClipboard',
  copyTooltip = 'Copy to clipboard',
  copiedTooltip = 'Copied to Clipboard!',
  handleCopy = noop,
  copiedMessageDelay = 2000,
  iconType = IconType.copy,
  hideOnTooltipMouseEnter = true,
  popperModifiers = [],
  ...otherProps
}: IProps) => {
  const props = {
    dataTest,
    copyTooltip,
    copiedTooltip,
    handleCopy,
    copiedMessageDelay,
    iconType,
    hideOnTooltipMouseEnter,
    popperModifiers,
    ...otherProps,
  };
  const [isCopied, setIsCopied] = useState(false);

  const onSuccess = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), props.copiedMessageDelay);
    props.handleCopy();
  };

  return (
    <Tooltip
      popperModifiers={ props.popperModifiers }
      hideOnTooltipMouseEnter={ props.hideOnTooltipMouseEnter }
      disabled={ !(props.isOutlineButtons || props.withTooltip) || props.isMobile }
      content={ isCopied ? props.copiedTooltip : props.copyTooltip }
    >
      <div
        className={ cn({ [props.className]: !props.isOutlineButtons }) }
        data-test={ props.dataTest }
      >
        <Clipboard
          component="span"
          data-clipboard-text={ props.content }
          onSuccess={ onSuccess }
        >
          <Button
            variant={ props.isOutlineButtons ? variantTypes.outline : variantTypes.text }
            disabled={ props.isDisabled || isCopied }
            className={ cn(props.customStyles,
              {
                [linkStyles.link]: !props.isOutlineButtons,
                [props.copyElemClassName]: !isCopied,
                [props.copiedElemClassName]: isCopied,
              },
            ) }
            dataTest={ `${ props.dataTest }Button` }
          >
            <Icon
              type={ isCopied && props.copiedIconType ? props.copiedIconType : props.iconType }
              className={ cn({ [styles.iconOutline]: props.isOutlineButtons }) }
            />
            <span>{ !isCopied ? props.labelBeforeCopy : props.labelAfterCopy }</span>
          </Button>
        </Clipboard>
      </div>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
