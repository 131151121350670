import { useState, useEffect, useRef } from 'react';
import { Placement } from '@popperjs/core';
import { Popper } from 'react-popper';
import cn from 'classnames';
import noop from 'lodash/noop';
import PortalWrp, { PortalId } from '@/ui/shared/components/Layout/PortalWrp';
import { contextMenuId, contextMenuPlacement } from './constants';
import { generateEventType } from './helpers';
import styles from './contextMenu.scss';

const generateVirtualReferenceElement = (x: number | string = 0, y: number | string = 0) => ({
  getBoundingClientRect: () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  }),
});

interface IProps {
  id?: string,
  placement?: Placement,
  className?: string,
  onShow?: (e: any) => void,
  children: React.ReactNode | React.ReactNode[],
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const ContextMenu = ({
  id = contextMenuId,
  placement = contextMenuPlacement,
  className,
  onShow = noop,
  children,
}: IProps) => {
  const contextMenuRef = useRef(null);
  const [virtualReferenceElement, setVirtualReferenceElement] = useState(generateVirtualReferenceElement());
  const [isVisible, setIsVisible] = useState(false);

  const handleShowContextMenu = (e) => {
    const { x, y } = e.detail;

    setVirtualReferenceElement(generateVirtualReferenceElement(x, y));
    setIsVisible(true);
    onShow(e);
  };

  const handleHideContextMenu = () => {
    setIsVisible(false);
  };

  const handleDocumentTouch = (e) => {
    if (!contextMenuRef.current.contains(e.target)) {
      handleHideContextMenu();
    }
  };

  useEffect(() => {
    window.addEventListener(generateEventType(id), handleShowContextMenu);
    return () => {
      window.removeEventListener(generateEventType(id), handleShowContextMenu);
    };
  }, [id, handleShowContextMenu]);

  useEffect(() => {
    isVisible && document.addEventListener('mousedown', handleDocumentTouch);
    isVisible && document.addEventListener('touchstart', handleDocumentTouch);
    return () => {
      document.removeEventListener('mousedown', handleDocumentTouch);
      document.removeEventListener('touchstart', handleDocumentTouch);
    };
  }, [isVisible]);

  return isVisible && (
    <PortalWrp portalId={ PortalId.PORTAL_POPPER_ID }>
      <Popper
        // @ts-ignore
        referenceElement={ virtualReferenceElement }
        placement={ placement }
        innerRef={ contextMenuRef }
      >
        { ({ ref, style, placement }) => (
          <div
            ref={ ref }
            data-test="contextMenu"
            style={ style }
            className={ cn(styles.contextMenu, className) }
            data-placement={ placement }
            onClick={ handleHideContextMenu }
          >
            { children }
          </div>
        ) }
      </Popper>
    </PortalWrp>
  );
};

export default ContextMenu;
