import React, { createContext, useContext, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import UserRepository from '@/dataroom/infrastructure/repository/UserRepository';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { QuestionAlertsType } from '@/dataroom/ui/common/EmailAlerts';
import { IFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IDigestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { IAccessRequestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsNotificationSettingsPayload, IQuestionsRealtimeNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';

type AllOrNone<T> = T | { [K in keyof T]?: never };

const useEmailAlerts = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { currentUser } = useSessionContext() as any;
  const { container } = useDIContext();

  const updateSettings = async ({
      dataroomName,
      userIds,
      isUploadAlertsDirty,
      uploadNotificationSettings,
      isStagingUploadAlertsDirty,
      stagingUploadNotificationSettings,
      isDigestAlertsDirty,
      digestNotificationSettings,
      isAccessRequestAlertsDirty,
      accessRequestNotificationSettings,
      isQuestionAlertsDirty,
      questionNotificationSettings,
      questionRealtimeNotificationSettings,
      questionNotificationTypeValue,
      isQuestionManager,
    }: {
    dataroomName: string,
    userIds?: number[],
  } & AllOrNone<{
    isUploadAlertsDirty: boolean,
    uploadNotificationSettings: IFileUploadNotificationSettingsPayload,
  }> & AllOrNone<{
    isStagingUploadAlertsDirty: boolean,
    stagingUploadNotificationSettings: IStagingFileUploadNotificationSettingsPayload,
  }> & AllOrNone<{
    isDigestAlertsDirty: boolean,
    digestNotificationSettings: IDigestNotificationSettingsPayload,
  }> & AllOrNone<{
    isAccessRequestAlertsDirty: boolean,
    accessRequestNotificationSettings: IAccessRequestNotificationSettingsPayload,
  }> & AllOrNone<{
    isQuestionAlertsDirty: boolean,
    questionNotificationSettings: IQuestionsNotificationSettingsPayload,
    questionRealtimeNotificationSettings: IQuestionsRealtimeNotificationSettingsPayload,
    questionNotificationTypeValue: QuestionAlertsType,
    isQuestionManager: boolean,
  }>) => {
    const { email } = currentUser || {};

    if (!email) {
      return false;
    }

    setIsFetching(true);

    try {
      const userRepository = container.get<UserRepository>(UserRepository);

      await Promise.all([
        ...(isUploadAlertsDirty ? [
          await userRepository.updateUploadNotificationSettings(getUploadNotificationSettings(
            dataroomName,
            userIds,
            uploadNotificationSettings,
          )),
        ] : []),
        ...(isStagingUploadAlertsDirty ? [
          await userRepository.updateStagingUploadNotificationSettings(getStagingUploadNotificationSettings(
            dataroomName,
            userIds,
            stagingUploadNotificationSettings,
          )),
        ] : []),
        ...(isDigestAlertsDirty ? [
          await userRepository.updateDigestNotificationSettings(getDigestNotificationSettings(
            dataroomName,
            userIds,
            digestNotificationSettings,
          )),
        ] : []),
        ...(isAccessRequestAlertsDirty ? [
          await userRepository.updateAccessRequestNotificationSettings(getAccessRequestNotificationSettings(
            dataroomName,
            userIds,
            accessRequestNotificationSettings,
          )),
        ] : []),
        ...(isQuestionAlertsDirty ? [
          await userRepository.updateQuestionNotificationSettings(getQuestionNotificationSettings(
            dataroomName,
            userIds,
            questionNotificationSettings,
            questionRealtimeNotificationSettings,
            questionNotificationTypeValue,
            isQuestionManager,
          )),
        ] : []),
      ]);

      NotificationManager.success(getMessage(messageCodes.DATAROOM_ALERTS_UPDATE, { userString: email }));
      return true;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error, { userString: email }));
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  const getUploadNotificationSettings = (
    dataroomName: string,
    userIds: number[],
    {
      isRealTime,
      isDaily,
      isWeekly,
      weeklyTime,
      weeklyDay,
      dailyTime,
    }: IFileUploadNotificationSettingsPayload,
  ) => ({
    dataroomName,
    ...(userIds ? { userIds } : {}),
    notifications: {
      realtime: {
        enabled: isRealTime,
      },
      daily: {
        enabled: isDaily,
        time: dailyTime,
      },
      weekly: {
        enabled: isWeekly,
        day: weeklyDay,
        time: weeklyTime,
      },
    },
  });

  const getStagingUploadNotificationSettings = (dataroomName: string, userIds: number[], {
    isRealTime,
    isDaily,
    isWeekly,
    weeklyTime,
    weeklyDay,
    dailyTime,
  }: IStagingFileUploadNotificationSettingsPayload) => ({
    dataroomName,
    ...(userIds ? { userIds } : {}),
    notifications: {
      stagingRealtime: {
        enabled: isRealTime,
      },
      stagingDaily: {
        enabled: isDaily,
        time: dailyTime,
      },
      stagingWeekly: {
        enabled: isWeekly,
        day: weeklyDay,
        time: weeklyTime,
      },
    },
  });

  const getDigestNotificationSettings = (dataroomName: string, userIds: number[], {
    isDigestDaily,
    isDigestWeekly,
    digestDailyTime,
    digestWeeklyTime,
    digestWeeklyDay,
  }: IDigestNotificationSettingsPayload) => ({
    dataroomName,
    ...(userIds ? { userIds } : {}),
    notifications: {
      digestDaily: {
        enabled: isDigestDaily,
        time: digestDailyTime,
      },
      digestWeekly: {
        enabled: isDigestWeekly,
        day: digestWeeklyDay,
        time: digestWeeklyTime,
      },
    },
  });

  const getAccessRequestNotificationSettings = (dataroomName: string, userIds: number[], {
    isRealTime,
    isDaily,
    isWeekly,
    weeklyTime,
    weeklyDay,
    dailyTime,
  }: IAccessRequestNotificationSettingsPayload) => ({
    dataroomName,
    ...(userIds ? { userIds } : {}),
    notifications: {
      accessRequestRealtime: {
        enabled: isRealTime,
      },
      accessRequestDaily: {
        enabled: isDaily,
        time: dailyTime,
      },
      accessRequestWeekly: {
        enabled: isWeekly,
        day: weeklyDay,
        time: weeklyTime,
      },
    },
  });

  const getQuestionNotificationSettings = (
    dataroomName: string,
    userIds: number[],
    {
      isQuestionDaily,
      isQuestionWeekly,
      isQuestionRealTime,
      questionDailyTime,
      questionWeeklyTime,
      questionWeeklyDay,
    }: IQuestionsNotificationSettingsPayload,
    {
      questionSubmitted,
      replySubmitted,
      noteSubmitted,
      questionAssigned,
    }: IQuestionsRealtimeNotificationSettingsPayload,
    questionNotificationTypeValue: QuestionAlertsType,
    isQuestionManager: boolean,
  ) => ({
    dataroomName,
    ...(userIds ? { userIds } : {}),
    notifications: {
      daily: {
        enabled: isQuestionDaily,
        time: [questionDailyTime],
      },
      weekly: {
        enabled: isQuestionWeekly,
        day: questionWeeklyDay,
        time: questionWeeklyTime,
      },
      allQuestions: !!questionNotificationTypeValue,
      realtime: {
        enabled: isQuestionRealTime,
        realtime: {
          questionSubmitted: isQuestionRealTime && questionSubmitted,
          replySubmitted: isQuestionRealTime && replySubmitted,
          noteSubmitted: isQuestionRealTime && isQuestionManager && noteSubmitted,
          questionAssigned: isQuestionRealTime && isQuestionManager && questionAssigned,
        },
      },
    },
  });

  return {
    isFetching,
    updateSettings,
  };
};

type EmailAlertsContextType = ReturnType<typeof useEmailAlerts>;

export const EmailAlertsContext = createContext<EmailAlertsContextType>(null);

export const useEmailAlertsContext = () => {
  const context = useContext(EmailAlertsContext);
  if (!context) {
    throw new Error('useEmailAlertsContext must be used within a EmailAlertsContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const EmailAlertsContextProvider = ({ children }: IProps) => (
  <EmailAlertsContext.Provider value={ useEmailAlerts() }>
    { children }
  </EmailAlertsContext.Provider>
);

export default EmailAlertsContextProvider;
