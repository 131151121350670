import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Spinner, ResponsiveModal as Modal } from '@dealroadshow/uikit';
import CreateFolderForm from './CreateFolderForm';
import styles from './createFolderModal.scss';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

interface IProps {
  parentFolder: IFolderTree,
  closeModal: () => void,
}

const CreateFolderModal = ({ parentFolder, closeModal }: IProps) => {
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isPermissionsSectionVisible, setIsPermissionsSectionVisible] = useState(false);
  const { createFolder: { isCreating } } = useFilesystemContext();
  const { currentUser } = useCurrentUserContext();

  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser);

  useEffect(() => {
    if (!parentFolder) {
      setIsPermissionsSectionVisible(false);
    }
  }, [parentFolder]);

  const handleShowPermissionsSection = () => {
    setIsPermissionsSectionVisible(true);
  };

  if (!parentFolder) {
    return null;
  }

  return (
    <Modal
      className={ cn(styles.createFolderModal, {
        [styles.isPermissionsSectionVisible]: isPermissionsSectionVisible,
      }) }
      onCloseClicked={ closeModal }
      isVisible
      isLoading={ isFormLoading }
      title="Create New Folder"
      modalBodyClassName={ styles.modalBody }
      dataTest="createFolderModal"
    >
      { (footerRefCallback) => (
        <>
          <CreateFolderForm
            parentFolder={ parentFolder }
            closeModal={ closeModal }
            isPermissionsSectionVisible={ isPermissionsSectionVisible }
            userHasSystemManageAccess={ userHasSystemManageAccess }
            onShowPermissionsSection={ handleShowPermissionsSection }
            initialValues={ {
              folders: [{ name: '' }],
            } }
            onLoaded={ () => { setIsFormLoading(false); } }
            footerRefCallback={ footerRefCallback }
          />
          <Spinner
            overlay
            overlayClassName={ styles.spinnerOverlay }
            isVisible={ isCreating }
          />
        </>
      ) }
    </Modal>
  );
};

export default CreateFolderModal;
