import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { validateSpecialSymbolsExistence } from '@/ui/shared/validators/validateSpecialSymbolsExistence';

export default (value) => groupValidators(
  validateRequiredFields(
    [{ fieldName: 'name', fieldCaption: 'Archive Name' }],
    {
      name: value?.trim(),
    },
  ),
  validateMaxLength({
    value,
    length: 128,
    fieldName: 'name',
    fieldCaption: 'Archive Name',
  }),
  validateSpecialSymbolsExistence({
    value,
    fieldName: 'name',
    fieldCaption: 'Archive Name',
  }),
);
