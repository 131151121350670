import env from '@/Framework/env/env';
import { IAllocateConfig } from '@/allocate/domain/vo/IAllocateConfig';

const config: IAllocateConfig = {
  name: 'Allocate',
  code: 'allocate',
  abbr: 'ALC',
  supportEmail: env('CREDENTIALS__SUPPORT__EMAIL'),
  options: {
    isNewApp: false,
  },
  isConsentForEDeliveryRequired: false,
};

export default config;
