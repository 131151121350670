import React from 'react';
import cn from 'classnames';
import { useBookmarkingContext } from '@/dataroom/application/BookmarkingContext';
import { IconType } from '@dealroadshow/uikit';
import styles from './bookmarkButton.scss';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import ActionButton from '@/ui/shared/components/Button/ActionButton';
import { variantTypes } from '@/ui/shared/components/Button/ActionButton/constants';
import hoverActionsStyles from '@/ui/shared/components/Tables/HoverActions/hoverActions.scss';

interface IProps {
  item: IFilesystemListItem,
  variant?: string,
  dataTest: string,
  className?: string,
}

const BookmarkButton = (
  {
    item,
    dataTest,
    variant = variantTypes.icon,
    className,
  }: IProps,
) => {
  const {
    isBookmarked,
    addToBookmarks,
    removeFromBookmarks,
  } = useBookmarkingContext();

  const isItemBookmarked = isBookmarked(item);

  const handleBookmarking = async () => {
    await (() => {
      if (isItemBookmarked) {
        removeFromBookmarks(item);
      } else {
        addToBookmarks(item);
      }
    })();
  };

  return (
    <ActionButton
      className={ cn(styles.bookmarkButton, className, {
        [hoverActionsStyles.textActionItem]: variant === variantTypes.text,
        [styles.isBookmarked]: isItemBookmarked,
      }) }
      title={ isItemBookmarked ? 'Bookmarked' : 'Bookmark' }
      variant={ variant }
      iconType={ IconType.bookmark }
      onClick={ handleBookmarking }
      dataTest={ dataTest }
    />
  );
};

export default BookmarkButton;
