import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { COOKIES_ACCEPTED, MARKETING_ACCEPTED } from '@/ui/shared/components/Landing/CookiesBanner/constants';
import { useDIContext } from '@/Framework/DI/DIContext';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';

const cookiesOptions = {
  domain: '',
  hostOnly: true,
  expires: 365,
};

const useCookieBanner = ({ isVisible }: { isVisible: boolean }) => {
  const { container } = useDIContext();
  const cookiesRepository = container.get(CookieRepository);

  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(isVisible);
  const [isManageCookiesOpen, setIsManageCookiesOpen] = useState<boolean>(false);
  const [isMarketingAccepted, setIsMarketingAccepted] = useState<boolean>(
      cookiesRepository.get(MARKETING_ACCEPTED) === 'true',
  );

  const closeBanner = useCallback(() => {
    cookiesRepository.set(COOKIES_ACCEPTED, true, cookiesOptions);
    setIsBannerVisible(false);
  }, [isMarketingAccepted]);

  const saveAll = useCallback(() => {
    cookiesRepository.set(COOKIES_ACCEPTED, true, cookiesOptions);
    cookiesRepository.set(MARKETING_ACCEPTED, true, cookiesOptions);
    setIsBannerVisible(false);
  }, []);

  const openCookiesSettings = () => {
    setIsManageCookiesOpen(true);
  };

  const onMarketingChange = () => {
    setIsMarketingAccepted((prevState) => !prevState);
  };

  useEffect(() => {
    if (!isBannerVisible) return;
    if (isMarketingAccepted) {
      cookiesRepository.set(MARKETING_ACCEPTED, isMarketingAccepted, cookiesOptions);
    } else {
      cookiesRepository.removeFromCurrentDomain(MARKETING_ACCEPTED);
    }
  }, [isMarketingAccepted]);

  useEffect(() => {
    if (!cookiesRepository.get(COOKIES_ACCEPTED)) {
      setIsBannerVisible(true);
    }
  }, []);

  return {
    isMarketingAccepted,
    isManageCookiesOpen,
    isBannerVisible,
    onMarketingChange,
    openCookiesSettings,
    closeBanner,
    saveAll,
  };
};

const CookiesBannerContext = createContext<ReturnType<typeof useCookieBanner>>(null);

export const useCookiesBannerContext = () => {
  const context = useContext(CookiesBannerContext);
  if (!context) {
    throw new Error('useCookiesBannerContext must be used within the CookiesBannerContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  value: boolean | null,
}

const CookiesBannerContextProvider = ({ children, value }: IProps) => (
  <CookiesBannerContext.Provider
    value={ useCookieBanner({ isVisible: !value }) }
  >{ children }
  </CookiesBannerContext.Provider>
);

export default CookiesBannerContextProvider;
