import React from 'react';
import cn from 'classnames';
import Name from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/Columns/Name';
import Path from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/Columns/Path';
import { ProgressRing } from '@dealroadshow/uikit';
import * as column from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/constants';
import FileUploaderItemActions
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/FileUploaderItemActions';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import stylesNameColumn from './Columns/Name/name.scss';
import styles from '../uploaderModal.scss';
import Upload from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/Upload';

export default () => [
  {
    name: column.NAME,
    title: 'File name',
    width: '36%',
    sortable: true,
    className: cn(alignStyles.alignLeft, styles.fileNameCol),
    cellCallback: (cellProps) => (
      <Name
        cellProps={ cellProps }
        className={ stylesNameColumn.nameCellWidth }
      />
    ),
  },
  {
    name: column.PATH,
    title: 'Path',
    width: '25%',
    sortable: true,
    className: styles.pathCol,
    cellCallback: (cellProps) => (
      <Path cellProps={ cellProps } />
    ),
  },
  {
    name: column.STATUS,
    title: 'status',
    width: '39%',
    sortable: false,
    className: alignStyles.alignRight,
    cellCallback: (cellProps: {
      row: {
        upload: Upload,
      },
    }) => {
      const { upload } = cellProps.row;
      const error = upload.errors[0];
      const file = cellProps.row;

      const isUploading = upload.isUploading() || upload.isPending() || upload.isJoining();

      if (isUploading) {
        return (
          <ProgressRing
            radius={ 10 }
            stroke={ 2 }
            progress={ upload.percentage }
          />
        );
      }

      return (
        <FileUploaderItemActions file={ file }>
          <div className={ styles.errorMessage }>{ error.displayMessage }</div>
        </FileUploaderItemActions>
      );
    },
  },
];
