import React, { createContext, useContext, useState } from 'react';
import ErrorCodeHelper from '@finsight/error-codes';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import TwoFactorRepository from '@/users/infrastructure/repository/TwoFactorRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/ui/shared/components/Notification';
import usersUrl from '@/users/infrastructure/usersUrl';
import { SessionService } from '@/users/application/Session/SessionService';
import { useDIContext } from '@/Framework/DI/DIContext';
import Logger from '@/Framework/browser/log/Logger';
import SSORepository from '@/users/infrastructure/repository/SSORepository';
import { domainRegexp } from '@/ui/shared/validators/validateDomain';

interface IPasswordFormData {
  password: string,
}

interface IEmailFormData {
  email: string,
}

interface IPhoneVerificationFormData {
  verificationPhone: {
    value: string,
    code: string,
  },
}

export type DeliveryMethodType = 'phone' | 'email';

const useTwoFactorAuthentication = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const checkPassword = async (
    router: {
      push: (url: string) => void,
      replace: (url: string) => void,
      pathname: string,
      asPath: string,
      query: { [key: string]: string },
    },
    { password }: IPasswordFormData,
  ): Promise<boolean | IPasswordFormData> => {
    const sessionService: SessionService = container.get(SessionService);
    const twoFactorRepository: TwoFactorRepository = container.get(TwoFactorRepository);

    const { replace } = router;
    // @ts-ignore
    const { mobileTwoFactor } = sessionService.currentUser;

    const pathname = `/two_factor_authentication/${ mobileTwoFactor ? 'turn_off' : 'add_phone' }`;

    try {
      const { updateToken } = await twoFactorRepository.generateTwoFactorToken(password);
      replace(`${ pathname }/${ updateToken }`);
    } catch (error) {
      if (error?.getCode() === ErrorCodeHelper.getCodeByName('USER_INVALID_PASSWORD')) {
        return { password: getErrorMessage(error) };
      }

      NotificationManager.error(getErrorMessage(error));
    }

    return true;
  };

  const ssoLoginVerification = async (
    router: {
      push: (url: string) => void,
      replace: (url: string) => void,
      pathname: string,
      asPath: string,
      query: { [key: string]: string },
    },
    { email }: IEmailFormData,
  ): Promise<void> => {
    const { replace, query: { callbackUrl } } = router;

    try {
      const domain = email.split('@')[1];
      if (!domain || !domainRegexp.test(domain)) return;
      const ssoRepository = container.get(SSORepository);
      const resp = await ssoRepository.checkIsSSOEmail({ domain });
      if (resp) {
        window.location.href = usersUrl.getSsoLoginUrl(email, callbackUrl);
      } else {
        replace('/profile');
      }
    } catch (e) {
      replace('/profile');
      Logger.error(e);
    }
  };

  const addPhone = async (
    router: {
      replace: (url: string) => void,
      asPath: string,
    },
    token: string,
    { verificationPhone }: IPhoneVerificationFormData,
  ): Promise<void> => {
    const { replace } = router;
    const twoFactorRepository: TwoFactorRepository = container.get(TwoFactorRepository);
    const sessionService: SessionService = container.get(SessionService);

    const { value: phone } = verificationPhone;
    try {
      await twoFactorRepository.addTwoFactorPhone(phone, token);
      await sessionService.getCurrentUser({ clearCache: true });
      replace('/two_factor_authentication/verifying_phone');
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const checkCode = async (code: string, successCallback: () => void): Promise<void> => {
    const sessionService: SessionService = container.get(SessionService);

    setIsFetching(true);
    setErrorMessage(null);

    try {
      const twoFactorRepository: TwoFactorRepository = container.get(TwoFactorRepository);
      await twoFactorRepository.checkTwoFactorCode(code);
      await sessionService.getCurrentSession({ clearCache: true });
      successCallback();
    } catch (error) {
      handleTwoFactorAuthenticationError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const sendCode = async (deliveryMethod: DeliveryMethodType): Promise<void> => {
    setIsFetching(true);
    setErrorMessage(null);

    try {
      const twoFactorRepository: TwoFactorRepository = container.get(TwoFactorRepository);
      await twoFactorRepository.sendTwoFactorCode(deliveryMethod);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const enableMobileTwoFactor = async (code: string, successCallback: () => void) => {
    const sessionService: SessionService = container.get(SessionService);

    setIsFetching(true);
    setErrorMessage(null);

    try {
      const twoFactorRepository: TwoFactorRepository = container.get(TwoFactorRepository);
      await twoFactorRepository.enableMobileTwoFactor(code);
      await sessionService.getCurrentUser({ clearCache: true });
      successCallback();
    } catch (error) {
      handleTwoFactorAuthenticationError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const turnOff = async (token: string, successCallback: () => void) => {
    const sessionService: SessionService = container.get(SessionService);

    setIsFetching(true);
    setErrorMessage(null);

    try {
      const twoFactorRepository: TwoFactorRepository = container.get(TwoFactorRepository);
      await twoFactorRepository.disableMobileTwoFactor(token);
      await sessionService.getCurrentUser({ clearCache: true });
      successCallback();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const resetTwoFactorData = (): void => {
    setIsFetching(false);
    setErrorMessage(null);
  };

  const handleTwoFactorAuthenticationError = (error: RpcError): void => {
    const twoFactorErrorCodes = [
      ErrorCodeHelper.getCodeByName('TWO_FACTOR_CODE_EXPIRED'),
      ErrorCodeHelper.getCodeByName('TWO_FACTOR_CODE_INVALID_VALUE'),
      ErrorCodeHelper.getCodeByName('TWO_FACTOR_FAIL_ATTEMPT'),
    ];

    if (twoFactorErrorCodes.includes(error?.getCode())) {
      setErrorMessage(getErrorMessage(error));
    } else {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return {
    isFetching,
    errorMessage,
    checkPassword,
    ssoLoginVerification,
    addPhone,
    checkCode,
    sendCode,
    enableMobileTwoFactor,
    turnOff,
    resetTwoFactorData,
  };
};

type TwoFactorAuthenticationType = ReturnType<typeof useTwoFactorAuthentication>;

export const TwoFactorAuthenticationContext = createContext<TwoFactorAuthenticationType>(null);

export const useTwoFactorAuthenticationContext = (): TwoFactorAuthenticationType => {
  const context = useContext<TwoFactorAuthenticationType>(TwoFactorAuthenticationContext);
  if (!context) {
    throw new Error('useTwoFactorAuthenticationContext must be used within a TwoFactorAuthenticationContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const TwoFactorAuthenticationContextProvider = ({ children }: IProps) => (
  <TwoFactorAuthenticationContext.Provider value={ { ...useTwoFactorAuthentication() } }>
    { children }
  </TwoFactorAuthenticationContext.Provider>
);

export default TwoFactorAuthenticationContextProvider;
