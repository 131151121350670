import React, { useState, useMemo, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import EmailAlertsContext, { useEmailAlertsContext } from '@/dataroom/application/EmailAlertsContext';
import {
  QuestionAlerts, QuestionAlertsType,
  QuestionAlertsTypeSwitcher,
} from '@/dataroom/ui/common/EmailAlerts';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import {
  IQuestionsNotificationSettingsPayload,
  IQuestionsRealtimeNotificationSettingsPayload,
} from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { Spinner, Modal } from '@dealroadshow/uikit';
import isDefined from '@/Framework/dataHelpers/isDefined';
import * as questionPermissions from '@/dataroom/domain/questionPermissions';
import styles from './editQuestionAlertsModal.scss';

interface IProps {
  closeModal: () => void,
}

const EditQuestionAlertsModal = ({ closeModal }: IProps) => {
  const [
    questionNotificationSettings,
    setQuestionNotificationSettings,
  ] = useState<IQuestionsNotificationSettingsPayload>(null);
  const [
    realtimeAlertsSettings,
    setRealtimeAlertsSettings,
  ] = useState<IQuestionsRealtimeNotificationSettingsPayload>(null);
  const [questionNotificationTypeValue, setQuestionNotificationTypeValue] = useState(QuestionAlertsType.All);
  const [realtimeValidationError, setRealtimeValidationError] = useState('');

  const { dataroom: { name: dataroomName, timezone } } = useDataroomContext();
  const { currentUser, getCurrentUser, isFetching: isUserFetching } = useCurrentUserContext();
  const { isFetching, updateSettings } = useEmailAlertsContext();

  const isQuestionManager = questionPermissions.isQuestionManager(currentUser);

  const {
    daily = {
      enabled: undefined,
      time: undefined,
    },
    weekly = {
      enabled: undefined,
      time: undefined,
      day: undefined,
    },
    allQuestions,
    realtime = {
      enabled: undefined,
      realtime: undefined,
    },
  } = currentUser.qnaNotificationSettings || {};

  useEffect(() => {
    setQuestionNotificationTypeValue(
      isDefined(allQuestions) ? Number(allQuestions) : QuestionAlertsType.All,
    );
  }, [allQuestions]);

  const alertsInitialValues = useMemo(() => ({
    isActiveQuestionAlerts: !daily.enabled && !weekly.enabled && !realtime.enabled,
    isQuestionDaily: daily.enabled || false,
    isQuestionWeekly: weekly.enabled || false,
    isQuestionRealTime: realtime.enabled || false,
    questionDailyTime: (Array.isArray(daily.time) ? daily.time[0] : daily.time) || '01:00:00',
    questionWeeklyTime: weekly.time || '01:00:00',
    questionWeeklyDay: weekly.day || 'Sunday',
  }), [currentUser.qnaNotificationSettings]);

  const realtimeInitialValues = useMemo(() => ({
    questionSubmitted: realtime.enabled ? realtime.realtime?.questionSubmitted : true,
    replySubmitted: realtime.enabled ? realtime.realtime?.replySubmitted : true,
    noteSubmitted: realtime.realtime?.noteSubmitted || false,
    questionAssigned: realtime.realtime?.questionAssigned || false,
  }), [currentUser.qnaNotificationSettings]);

  const isAlertsDirty = !!questionNotificationSettings &&
    !isEqual(questionNotificationSettings, alertsInitialValues);

  const isAlertsTypeDirty = (
    isDefined(allQuestions) ? Number(allQuestions) : QuestionAlertsType.All) !== questionNotificationTypeValue;

  const isRealtimeTypeDirty = questionNotificationSettings?.isQuestionRealTime &&
    !!realtimeAlertsSettings && !isEqual(realtimeAlertsSettings, realtimeInitialValues);

  const handleSaveNotificationAlerts = async () => {
    const success = await updateSettings({
      dataroomName,
      isQuestionAlertsDirty: isAlertsDirty || isAlertsTypeDirty || isRealtimeTypeDirty,
      questionNotificationSettings,
      questionRealtimeNotificationSettings: realtimeAlertsSettings,
      questionNotificationTypeValue,
      isQuestionManager,
    });

    if (success) {
      await getCurrentUser();
      closeModal();
    }
  };

  const content = (
    <div>
      <div className={ styles.container }>
        <div className={ styles.alertsTitle }>
          Q&A Alerts provide status reports on questions submitted to the data room, directly by email.
        </div>
        <QuestionAlerts
          initialValues={ alertsInitialValues }
          initialRealtimeValues={ realtimeInitialValues }
          timezone={ timezone }
          onChange={ setQuestionNotificationSettings }
          onRealtimeChange={ setRealtimeAlertsSettings }
          isQuestionManager={ isQuestionManager }
          realtimeError={ realtimeValidationError }
          setRealtimeError={ setRealtimeValidationError }
        />
      </div>
      <div className={ cn(styles.container, styles.bottomSection) }>
        <div className={ styles.alertsTitle }>
          Select which Q&A email alerts on this data room you’ll receive emails notifications for:
        </div>
        <QuestionAlertsTypeSwitcher
          onChange={ setQuestionNotificationTypeValue }
          value={ questionNotificationTypeValue }
        />
      </div>
      <Spinner
        overlay
        isVisible={ isFetching || isUserFetching }
      />
    </div>
  );

  const footer = (
    <div>
      <Button
        type="submit"
        onClick={ handleSaveNotificationAlerts }
        disabled={
        (!isAlertsDirty && !isAlertsTypeDirty && !isRealtimeTypeDirty) ||
          isFetching ||
          isUserFetching ||
          !!realtimeValidationError
        }
        variant={ variantTypes.action }
        title="Save"
        dataTest="save"
      />
      <Button
        onClick={ closeModal }
        disabled={ isFetching }
        variant={ variantTypes.text }
        title="Cancel"
        dataTest="cancel"
      />
    </div>
  );

  return (
    <Modal
      onCloseClicked={ closeModal }
      isVisible
      title="Edit Q&A Email Alerts"
      footer={ footer }
      className={ styles.modal }
      hideOnOverlayClicked
      onOverlayClicked={ closeModal }
      dataTest="editQuestionAlertsModal"
    >
      { content }
    </Modal>
  );
};

export default (props) => (
  <EmailAlertsContext>
    <EditQuestionAlertsModal { ...props } />
  </EmailAlertsContext>
);
