import { EventEmitter } from 'events';
import { v4 as uuid } from 'uuid';
import React from 'react';
import NotificationType from './constants';
import { INotificationsProps } from '@/ui/shared/components/Notification/Notifications';

class NotificationManager extends EventEmitter {
  listNotify: INotificationsProps[];

  constructor() {
    super();
    this.listNotify = [];
  }

  /**
   * @param {Object} notify
   *
   * @return {Object}
   */
  create(notify) {
    const defaultNotify = {
      id: uuid(),
      type: 'info',
      title: null,
      message: null,
      timeOut: 5000,
      iconType: null,
    };

    let notification = Object.assign(defaultNotify, notify);

    this.listNotify.push(notification);
    this.emitChange();

    return notification;
  }

  /**
   * Show info notification
   *
   * @param {String||Object} notification
   *
   * @return {Object}
   */
  info(notification) {
    return this.create(this.createNotification(NotificationType.INFO, notification));
  }

  /**
   * Show success notification
   *
   * @param {String||Object} notification
   *
   * @return {Object}
   */
  success(notification) {
    return this.create(this.createNotification(NotificationType.SUCCESS, notification));
  }

  /**
   * Show warning notification
   *
   * @param {String||Object} notification
   *
   * @return {Object}
   */
  warning(notification) {
    return this.create(this.createNotification(NotificationType.WARNING, notification));
  }

  /**
   * Show error notification
   *
   * @param {String||Object} notification
   *
   * @return {Object}
   */
  error(notification) {
    return this.create(this.createNotification(NotificationType.ERROR, notification));
  }

  /**
   * Parse input params to valid notification object
   *
   * @param {String} type
   * @param {Object||String} notification
   *
   * @return {{type: *, message: *, title: *, timeOut: *, onClick: *, iconType: *}}
   */
  createNotification(type, notification) {
    let { message, title, timeOut, onClick, iconType } = notification;
    if (typeof notification === 'string' || React.isValidElement(notification)) {
      message = notification;
    }

    return {
      type,
      message,
      title,
      timeOut,
      onClick,
      iconType,
    };
  }

  /**
   * @param {Object} notification
   */
  remove(notification) {
    this.listNotify = this.listNotify.filter((n) => notification.id !== n.id);
    this.emitChange();
  }

  emitChange() {
    this.emit(NotificationType.CHANGE, this.listNotify);
  }

  /**
   * @param {Function} callback
   */
  addChangeListener(callback) {
    this.addListener(NotificationType.CHANGE, callback);
  }

  /**
   * @param {Function} callback
   */
  removeChangeListener(callback) {
    this.removeListener(NotificationType.CHANGE, callback);
  }

  /**
   * @returns {Array} array of notifications
   */
  getNotificationsList() {
    return this.listNotify;
  }
}

export default new NotificationManager();
