import { useState } from 'react';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import DataroomErrorHandler, { getFailedItems } from '@/dataroom/application/ErrorHandler';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';

const useCreateFolder = () => {
  const { container } = useDIContext();
  const [isCreating, setIsCreating] = useState(false);

  const { dataroom } = useDataroomContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const { updateFolderTree } = useFolderTreeContext();

  const createFolders = async (
    parentFolder: IFilesystemListItem | IFolderTree,
    formData: { folders: { name: string }[] },
    permissionGroups: Partial<{ [key in PermissionGroup]: number[] }>,
  ) => {
    setIsCreating(true);

    try {
      const folders = formData.folders.map((folder) => ({
        ...folder,
        destination: {
          id: parentFolder.id,
        },
      }));

      const customPermissionGroups = Object.keys(permissionGroups).reduce((acc, permission: PermissionGroup) => {
        permissionGroups[permission].forEach((id) => acc.push({ id, permission }));
        return acc;
      }, []);

      const payload = {
        dataroomId: dataroom.id,
        folders,
        customPermissionGroups,
      };

      await container.get<FolderRepository>(FolderRepository).createFolders(payload);

      const successMessage = folders.length === 1
        ? getMessage(messageCodes.DATAROOM_CREATE_FOLDER_SUCCESS, { name: folders[0].name })
        : getMessage(messageCodes.DATAROOM_CREATE_FOLDERS_SUCCESS, { count: folders.length });

      NotificationManager.success(successMessage);

      updateFolderTree(parentFolder.id);
      updateDataroomExplorerCollection && updateDataroomExplorerCollection();
    } catch (error) {
      const { failedNames } = getFailedItems(error);

      if (failedNames) {
        NotificationManager.error(getMessage(messageCodes.DATAROOM_CREATE_FOLDERS_ERROR, {
          folderNamesPrefix: `${ failedNames.join(', ') } ${ failedNames.length > 1 ? 'have' : 'has' }`,
        }));
      } else {
        container.get(DataroomErrorHandler).handleError(error);
      }
    } finally {
      setIsCreating(false);
    }
  };

  return {
    isCreating,
    createFolders,
  };
};

export default useCreateFolder;
