import React from 'react';
import Input from '@/ui/shared/components/Form/Input';
import validate from './validator';
import styles from '../preloadModal.scss';

interface IProps {
  setCurrentArchiveName: (name: string) => void,
  currentArchiveName: string,
  error: string,
  setError: (error: string) => void,
}

const NameField = (
  {
    setCurrentArchiveName,
    currentArchiveName,
    error,
    setError,
  }: IProps,
) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setCurrentArchiveName(value);
    if (value.length) {
      // @ts-ignore
      const { name } = validate(value);
      setError(name);
    } else {
      setError('');
    }
  };

  return (
    <>
      { /* @ts-ignore */ }
      <Input
        name="name"
        isClearable
        placeholder="Optional: Rename your file archive here"
        dataTest="archiveNameInput"
        value={ currentArchiveName }
        onChange={ handleChange }
        formFieldClassName={ styles.input }
      />
      { error && (
        <div className={ styles.error }>
          { error }
        </div>
      ) }
    </>
  );
};

export default NameField;
