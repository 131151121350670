import React, { useRef, useEffect, useCallback } from 'react';
import { VariableSizeList, ListOnItemsRenderedProps, ListChildComponentProps } from 'react-window';
import VirtualDataTableContext, { useVirtualDataTableContext } from './VirtualDataTableContext';
import VirtualBodyRow from './VirtualBodyRow';
import InnerComponent from './InnerComponent';
import OuterComponent from './OuterComponent';
import { defaultRowWidth } from './constants';
import { getItemStyle, overrideRangeToRender } from './overrides';
import { IVirtualDataTableProps, TVirtualBody } from './interfaces';

const VirtualDataTable = ({
  data = [],
}: { data: IVirtualDataTableProps['data'] }) => {
  const {
    getSize,
    overscan,
    backwardOverscan,
    style,
    dataTableProps: {
      layoutContentId,
      bodyHeight,
      bodyRef,
      onScroll,
    },
  } = useVirtualDataTableContext();

  const ref = useRef<TVirtualBody>();
  const innerRef = useRef<HTMLDivElement>();
  const listRef = bodyRef || ref;

  const updatePosition = (top: string | number) => {
    const tableContainer = innerRef.current?.children?.[0] as HTMLElement;
    if (tableContainer) {
      tableContainer.style.top = `${ top }px`;
    }
  };

  const handleRenderItems = (props: ListOnItemsRenderedProps & {
    onItemsRendered?: ((props: ListOnItemsRenderedProps) => void) | undefined,
  }) => {
    const { top } = getItemStyle(listRef, props.overscanStartIndex) || { top: 0 };
    updatePosition(top);
    props.onItemsRendered && props.onItemsRendered(props);
  };

  const VirtualBodyRowComponent = useCallback((props: ListChildComponentProps) => (
    <VirtualBodyRow
      virtualDataTableRef={ listRef }
      { ...props }
    />
  ), [listRef]);

  useEffect(() => {
    backwardOverscan && overrideRangeToRender(listRef, overscan);
  }, [overscan]);

  return (
    <VariableSizeList
      ref={ listRef }
      innerRef={ innerRef }
      style={ style }
      itemSize={ getSize }
      itemCount={ data.length }
      height={ bodyHeight || window.innerHeight }
      width={ defaultRowWidth }
      innerElementType={ InnerComponent }
      outerElementType={ layoutContentId ? OuterComponent : null }
      onItemsRendered={ handleRenderItems }
      overscanCount={ overscan }
      onScroll={ onScroll }
    >
      { VirtualBodyRowComponent }
    </VariableSizeList>
  );
};

export default (props: IVirtualDataTableProps) => (
  <VirtualDataTableContext { ...props }>
    <VirtualDataTable data={ props.data } />
  </VirtualDataTableContext>
);
