const defaultOptions = {
  symbol: '',
  symbolOnLeft: false,
  spaceBetweenValueAndSymbol: false,
  thousandsSeparator: ',',
  decimalsSeparator: '.',
  decimals: 0,
};

/**
 * @param {Number} value
 * @param {Object} options
 * @return {String}
 */
export default function formatNumber(value, options = defaultOptions) {
  options = { ...defaultOptions, ...options };
  let symbolOnLeft = options.symbolOnLeft ? options.symbol : '';
  let spaceSymbol = options.spaceBetweenValueAndSymbol ? ' ' : '';

  return symbolOnLeft + spaceSymbol + numberFormat(
    value,
    options.decimals,
    options.decimalsSeparator,
    options.thousandsSeparator,
  );
}

/**
 * @param {Number} number
 * @param {Number} decimals
 * @param {String} decPoint
 * @param {String} thousandsSep
 * @return {String}
 */
function numberFormat(number, decimals, decPoint, thousandsSep) {
  // eslint-disable-next-line no-restricted-globals
  let n = !isFinite(+number) ? 0 : +number;
  // eslint-disable-next-line no-restricted-globals
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  let dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let toFixedFix = (n, prec) => {
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    // eslint-disable-next-line no-restricted-properties
    let k = Math.pow(10, prec);
    return Math.round(n * k) / k;
  };
  let s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}
