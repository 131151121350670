import screenVariables from '@/ui/shared/styles/screen/screen.scss';
import isServer from '@/Framework/Router/Next/isServer';

export const isScreenXXs = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screenXXs })`).matches;
export const isScreenXs = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screenXs })`).matches;
export const isScreen568 = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screen568 })`).matches;
export const isScreen640 = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screen640 })`).matches;
export const isScreenS = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screenS })`).matches;
export const isScreenM = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screenM })`).matches;
export const isScreenMOrientationLandscape = () => !isServer() && matchMedia(`(orientation: landscape) and (max-width: ${ screenVariables.screenM })`).matches;
export const isScreenL = () => !isServer() && matchMedia(`(max-width: ${ screenVariables.screenL })`).matches;
