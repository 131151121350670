import React from 'react';
import cn from 'classnames';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import { Icon, IconType } from '@dealroadshow/uikit';
import ScrollBodyBackground from '@/ui/shared/components/Layout/ScrollBodyBackground';
import styles from './applicationFallback.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';
import screenWithWarning from './assets/screen-with-warning.svg';

const onReloadClick = () => window.location.reload();

/**
 * @return {ReactElement}
 */
const ApplicationFallback = () => (
  <div className={ cn(styles.container) }>
    <ScrollBodyBackground tenant="fallback" />

    <img
      src={ screenWithWarning }
      alt="Something went wrong"
    />

    <div className={ styles.messageWrapper }>
      <h1 className={ cn(styles.messageHeader, headerStyles.isH1) }>
        Something went wrong
      </h1>
      <div className={ styles.message }>
        Please <a href={ finsightWebUrl.getContactUsUrl() }>contact</a> support or reload
      </div>
      <div className={ styles.reloadWrapper }>
        <a
          className={ styles.reloadLink }
          onClick={ onReloadClick }
        >
          <Icon
            className={ styles.reloadIcon }
            type={ IconType.rotate }
          />
          Reload
        </a>
      </div>
    </div>
  </div>
);

export default ApplicationFallback;
