import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import {
  groupValidators,
} from '@/ui/shared/validators/helpers';
import { validateName } from '@/ui/shared/validators/user/validateName';
import { validatePhoneNumber } from '@/ui/shared/validators/user/validatePhoneNumber';
import { validateEmail } from '@/ui/shared/validators/user/validateEmail';
import { validateRecaptchaVerified } from '@/ui/shared/validators/validateRecaptchaVerified';
import { validateCompanyName } from '@/ui/shared/validators/company/validateCompanyName';

const firstName = {
  fieldName: 'firstName',
  fieldCaption: 'First Name',
};

const lastName = {
  fieldName: 'lastName',
  fieldCaption: 'Last Name',
};

const phoneNumber = {
  fieldName: 'phoneNumber',
  fieldCaption: 'Phone Number',
};

const corporateEmail = {
  fieldName: 'corporateEmail',
  fieldCaption: 'Corporate Email',
};

const companyName = {
  fieldName: 'companyName',
  fieldCaption: 'Company Name',
};

const copyCount = {
  fieldName: 'copyCount',
  fieldCaption: 'Count',
};

const addressLine1 = {
  fieldName: 'addressLine1',
  fieldCaption: 'Address Line',
};

const country = {
  fieldName: 'country',
  fieldCaption: 'Country',
};

const city = {
  fieldName: 'city',
  fieldCaption: 'City',
};

const zipCode = {
  fieldName: 'zipCode',
  fieldCaption: 'Code',
};

export default (values, isFromDataroom) => {
  let validators = groupValidators(
    validateName({ fieldName: 'firstName', fieldCaption: 'First Name', value: values.firstName }),
    validateName({ fieldName: 'lastName', fieldCaption: 'Last Name', value: values.lastName }),
    validatePhoneNumber({
      fieldName: 'phoneNumber',
      fieldCaption: 'Phone Number',
      value: values.phoneNumber,
      isRequired: true,
    }),
    validateEmail({ fieldName: 'corporateEmail', value: values.corporateEmail }),
    validateCompanyName({ fieldName: 'companyName', fieldCaption: 'Company Name', value: values.companyName }),
    validateRequiredFields([
      firstName,
      lastName,
      phoneNumber,
      corporateEmail,
      companyName,
      copyCount,
      addressLine1,
      country,
      city,
      zipCode,
    ], values),
  );

  if (!isFromDataroom) {
    validators = groupValidators(
      validators,
      validateRecaptchaVerified({ fieldName: 'recaptchaToken', value: values.recaptchaToken }),
    );
  }

  return validators;
};
