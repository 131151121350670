import { useState, useCallback, useEffect } from 'react';

interface IProps {
  inputProps: {
    value: string,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
  },
  maxLength?: number,
  maxHeight?: number,
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  isDisabled: boolean,
}

const useTextAreaAutosize = (
  {
    inputProps: { value, onChange },
    maxLength,
    maxHeight,
    textAreaRef,
    isDisabled,
  }: IProps,
) => {
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');

  const textAreaScrollHeight = textAreaRef?.current?.scrollHeight || 0;

  useEffect(
    () => {
      if (!isDisabled) {
        const height = (textAreaScrollHeight >= maxHeight ? maxHeight : textAreaScrollHeight);
        setParentHeight(value ? `${ height }px` : 'auto');
        setTextAreaHeight(value ? `${ height }px` : 'auto');
      }
    },
    [value],
  );

  const onChangeWithAutosize = useCallback(
    (event) => {
      if (!maxLength || (maxLength > value?.length)) {
        setTextAreaHeight('auto');
        setParentHeight(`${ textAreaScrollHeight }px`);
      }
      onChange(event);
    },
    [textAreaHeight],
  );

  return {
    onChangeWithAutosize,
    textAreaHeight,
    parentHeight,
  };
};

export default useTextAreaAutosize;
