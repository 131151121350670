import React, { createContext, useCallback, useContext, useRef } from 'react';
import { defaultRowHeight } from './constants';
import { IVirtualDataTableProps, TVirtualBody } from './interfaces';

const useVirtualDataTable = ({
  bodyHeight,
  overscanCount,
  backwardOverscan = true,
}: IVirtualDataTableProps) => {
  const sizeMap = useRef<{ [key: number]: number }>({});

  const setSize = useCallback((
    virtualListRef: React.RefObject<TVirtualBody>,
    index: number,
    size: number,
  ) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    virtualListRef.current?.resetAfterIndex(index);
  }, []);

  const getSize = (index: number) => sizeMap.current[index] || defaultRowHeight;

  const overscan = overscanCount || 2 * Math.round(
    (bodyHeight || window?.innerHeight || 0) / defaultRowHeight,
  );

  const style = {
    ...(bodyHeight ? { maxHeight: bodyHeight } : {}),
    overflow: bodyHeight ? 'auto' : 'visible',
  };

  return {
    setSize,
    getSize,
    overscan,
    backwardOverscan,
    style,
  };
};

type VirtualDataTableContextType = ReturnType<typeof useVirtualDataTable>;

export const VirtualDataTableContext = createContext<VirtualDataTableContextType & {
  dataTableProps: IVirtualDataTableProps,
}>(undefined);

export const useVirtualDataTableContext = () => {
  const context = useContext(VirtualDataTableContext);
  if (!context) {
    throw new Error('useVirtualDataTableContext must be used within a VirtualDataTableContextProvider');
  }
  return context;
};

interface IProps extends IVirtualDataTableProps {
  children: React.ReactNode,
}

const VirtualDataTableContextProvider = ({ children, ...props }: IProps) => (
  <VirtualDataTableContext.Provider
    value={ {
      ...useVirtualDataTable(props),
      dataTableProps: props,
    } }
  >
    { children }
  </VirtualDataTableContext.Provider>
);

export default VirtualDataTableContextProvider;
