import React, { ChangeEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { getNotificationSummary } from '../helpers';
import { DAY_OF_WEEK_LIST, HOURS_OPTIONS } from '../common/constants';
import SelectField from '../common/SelectField';
import CheckboxField from '../common/CheckboxField';
import { Spinner } from '@dealroadshow/uikit';
import { IFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IAccessRequestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import styles from './alerts.scss';

interface IProps<T> {
  name: string,
  initialValues: T,
  isFetching?: boolean,
  isDisabled?: boolean,
  timezone?: ITimezone,
  onChange: (value: T) => void,
}

const Alerts = <T extends IFileUploadNotificationSettingsPayload |
  IStagingFileUploadNotificationSettingsPayload |
  IAccessRequestNotificationSettingsPayload,
>({
  name = 'dataroomAlertsForm',
  initialValues,
  isFetching = false,
  isDisabled,
  timezone,
  onChange = noop,
}: IProps<T>) => {
  const [alertsState, setAlertsState] = useState<T>(initialValues);

  useEffect(() => {
    onChange(alertsState);
  }, [alertsState]);

  useEffect(() => {
    isDisabled
      ? setAlertsState({ ...initialValues, isActiveAlerts: true, isDaily: false })
      : setAlertsState(initialValues);
  }, [initialValues, isDisabled]);

  const handleCheckboxFieldChange = (
    event: ChangeEvent<{
      name: keyof T & `is${ string }`,
      checked: T[keyof T & `is${ string }`],
    }>,
  ) => {
    const { name: field, checked: value } = event.target;

    const state: T = {
      ...alertsState,
      [field]: value,
    };

    if (field === 'isActiveAlerts') {
      state.isRealTime = false;
      state.isDaily = false;
      state.isWeekly = false;
    } else {
      state.isActiveAlerts = false;
    }

    if (
      !state.isActiveAlerts &&
      !state.isWeekly &&
      !state.isDaily &&
      !state.isRealTime
    ) {
      state.isActiveAlerts = true;
    }

    setAlertsState(state);
  };

  const handleSelectFieldChange = (
    name: keyof Omit<T, keyof T & `is${ string }`>,
    value: T[keyof Omit<T, keyof T & `is${ string }`>],
  ) => {
    setAlertsState({
      ...alertsState,
      [name]: value,
    });
  };

  return (
    <div data-test={ name }>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isActiveAlerts"
          dataTest="noneCheckbox"
          label="None"
          disabled={ isDisabled }
          value={ alertsState.isActiveAlerts }
          onChange={ handleCheckboxFieldChange }
        />
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isRealTime"
          dataTest="realtimeCheckbox"
          label="Real-time"
          disabled={ isDisabled }
          value={ alertsState.isRealTime }
          onChange={ handleCheckboxFieldChange }
        />
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isDaily"
          dataTest="dailyCheckbox"
          label="Daily summary"
          disabled={ isDisabled }
          value={ alertsState.isDaily }
          onChange={ handleCheckboxFieldChange }
        />
        <div className={ cn(styles.inlineBlock, styles.wrpSelect) }>
          <SelectField<T>
            name="dailyTime"
            dataTest="dailyTimeAlertSelect"
            selectClassName={ styles.timeSelect }
            options={ HOURS_OPTIONS }
            disabled={ !alertsState.isDaily || isDisabled }
            value={ alertsState.dailyTime }
            onChange={ handleSelectFieldChange }
          />
        </div>
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isWeekly"
          dataTest="weeklyCheckbox"
          label="Weekly summary"
          disabled={ isDisabled }
          value={ alertsState.isWeekly }
          onChange={ handleCheckboxFieldChange }
        />
        <div className={ cn(styles.inlineBlock, styles.wrpSelect) }>
          <SelectField<T>
            name="weeklyTime"
            dataTest="weeklyTimeAlertSelect"
            selectClassName={ styles.timeSelect }
            options={ HOURS_OPTIONS }
            disabled={ !alertsState.isWeekly || isDisabled }
            value={ alertsState.weeklyTime }
            onChange={ handleSelectFieldChange }
          />
        </div>
        <div className={ cn(
          styles.inlineBlock,
          styles.dayWeek,
        ) }
        >
          <SelectField<T>
            name="weeklyDay"
            dataTest="weeklyDayAlertSelect"
            selectClassName={ styles.selectDaysOfWeek }
            options={ DAY_OF_WEEK_LIST }
            disabled={ !alertsState.isWeekly || isDisabled }
            value={ alertsState.weeklyDay }
            onChange={ handleSelectFieldChange }
          />
        </div>
      </div>
      { timezone && (
        <div className={ styles.footer }>
          <span className={ styles.strong }>
            Summary:
          </span> { getNotificationSummary(alertsState, timezone.abbr) }
        </div>
      ) }
      <Spinner
        overlay
        isVisible={ isFetching }
      />
    </div>
  );
};

export default Alerts;
