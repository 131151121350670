import cn from 'classnames';
import React from 'react';
import styles from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions/changePermissions.scss';
import { PermissionGroupCheckbox, PermissionGroupHeaderLabel } from '@/dataroom/ui/common/PermissionGroups';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import { permissionGroups } from '@/dataroom/ui/common/PermissionGroups/constants';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const {
  manage: { name: manage },
  viewAndDownload: { name: viewAndDownload },
  viewOnly: { name: viewOnly },
  noAccess: { name: noAccess },
} = permissionGroups;

const mixedWarningTooltip = 'This permission group has sub-folder or sub-file permissions that are different than the permissions set here.';

export default (title) => [
  {
    name: 'permissionGroupName',
    title: <span className={ styles.title }> { title }</span>,
    className: cn(alignStyles.alignLeft, styles.permissionGroupNameColumn),
    cellCallback: (cellProps) => (
      <span className={ styles.nameColumnWrp }>
        <Tooltip
          content={ cellProps.row.permissionGroupName }
          containerClassName={ styles.tooltipContainer }
          wrpClassName={ styles.tooltipWrapper }
          maxWidth="auto"
          hideOnClick
        >
          { cellProps.row.permissionGroupName }
        </Tooltip>
        { cellProps.row.hasMixedPermissions && (
          <Tooltip
            content={ mixedWarningTooltip }
            containerClassName={ styles.warningIconContainer }
          >
            <Icon
              type={ IconType.warning }
              className={ styles.warningIcon }
            />
          </Tooltip>
        ) }
      </span>
    ),
  },
  {
    name: manage,
    title: <PermissionGroupHeaderLabel
      permissionGroup={ permissionGroups.manage }
      name={ manage }
      reversedArrays={ [
        viewAndDownload,
        viewOnly,
        noAccess,
      ] }
      isUnCheckEnable={ false }
    />,
    width: 82,
    className: alignStyles.alignCenter,
    cellCallback: (cellProps) => (
      <PermissionGroupCheckbox
        permissionGroup={ permissionGroups.manage }
        name={ manage }
        value={ cellProps.row.permissionGroupId }
        reversedArrays={ [
          viewAndDownload,
          viewOnly,
          noAccess,
        ] }
        isUnCheckEnable={ false }
        disabled={ cellProps.row.isDisabled }
      />
    ),
  },
  {
    name: viewAndDownload,
    title: <PermissionGroupHeaderLabel
      permissionGroup={ permissionGroups.viewAndDownload }
      name={ viewAndDownload }
      reversedArrays={ [
        manage,
        viewOnly,
        noAccess,
      ] }
      isUnCheckEnable={ false }
    />,
    width: 96,
    className: alignStyles.alignCenter,
    cellCallback: (cellProps) => (
      <PermissionGroupCheckbox
        permissionGroup={ permissionGroups.viewAndDownload }
        name={ viewAndDownload }
        value={ cellProps.row.permissionGroupId }
        reversedArrays={ [
          manage,
          viewOnly,
          noAccess,
        ] }
        isUnCheckEnable={ false }
        disabled={ cellProps.row.isDisabled }
      />
    ),
  },
  {
    name: viewOnly,
    title: <PermissionGroupHeaderLabel
      permissionGroup={ permissionGroups.viewOnly }
      name={ viewOnly }
      reversedArrays={ [
        manage,
        viewAndDownload,
        noAccess,
      ] }
      isUnCheckEnable={ false }
    />,
    width: 92,
    className: alignStyles.alignCenter,
    cellCallback: (cellProps) => (
      <PermissionGroupCheckbox
        permissionGroup={ permissionGroups.viewOnly }
        name={ viewOnly }
        value={ cellProps.row.permissionGroupId }
        reversedArrays={ [
          manage,
          viewAndDownload,
          noAccess,
        ] }
        isUnCheckEnable={ false }
        disabled={ cellProps.row.isDisabled }
      />
    ),
  },
  {
    name: noAccess,
    title: (
      <PermissionGroupHeaderLabel
        permissionGroup={ permissionGroups.noAccess }
        name={ noAccess }
        reversedArrays={ [
          manage,
          viewAndDownload,
          viewOnly,
        ] }
        isUnCheckEnable={ false }
      />
    ),
    width: 105,
    className: alignStyles.alignCenter,
    cellCallback: (cellProps) => (
      <PermissionGroupCheckbox
        permissionGroup={ permissionGroups.noAccess }
        name={ noAccess }
        value={ cellProps.row.permissionGroupId }
        reversedArrays={ [
          viewAndDownload,
          manage,
          viewOnly,
        ] }
        isUnCheckEnable={ false }
        disabled={ cellProps.row.isDisabled }
      />
    ),
  },
];
