import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import FilesystemRepository, { IRenameResult } from '@/dataroom/infrastructure/repository/FilesystemRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';
import capitalize from '@/Framework/dataHelpers/string/capitalize';

export interface IBulkRenameFormData {
  folders: {
    id: number,
    name: string,
  }[],
  files: {
    id: number,
    name: string,
    extension: string,
  }[],
  links: {
    id: number,
    name: string,
    href: string,
  }[],
}

const useBulkRename = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const { dataroom } = useDataroomContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const { updateFolderTree } = useFolderTreeContext();

  const getConflictErrorMessage = (conflictItems: IFilesystemItem[]) => {
    const itemsByType: { [key: string]: string[] } = conflictItems.reduce((result, item) => {
      if (result[item.type]) {
        result[item.type].push(item.name);
      } else {
        result[item.type] = [item.name];
      }
      return result;
    }, {});

    const arrayMessage = Object
      .entries(itemsByType)
      .map(([key, value]) => (
        `${ key } name${ value.length > 1 ? 's' : '' } ${ value.map((item) => `"${ item }"`).join(', ') }`
      ));

    NotificationManager.error(
      `${ capitalize(arrayMessage.join(' and ')) } already exist${ conflictItems.length > 1 ? '' : 's' }`,
    );
  };

  const handleError = (result: IRenameResult) => {
    if (result.failedItems.length) {
      NotificationManager.error('Validation failed');
    }

    if (result.conflictItems.length) {
      getConflictErrorMessage(result.conflictItems);
    }
  };

  const bulkRename = async (formData: IBulkRenameFormData, onSuccess: () => void) => {
    const files = formData.files.map((file) => ({
      id: file.id,
      name: `${ file.name }.${ file.extension }`,
    }));
    setIsFetching(true);

    const onFinish = () => {
      setIsFetching(false);
      onSuccess();
      updateDataroomExplorerCollection();
      if (formData.folders.length) {
        updateFolderTree(...formData.folders.map((folder) => folder.id));
      }
      NotificationManager.success(getMessage(messageCodes.DATAROOM_CHANGES_SUCCESSFULLY_SAVED));
    };

    const onError = (error) => {
      setIsFetching(false);
      NotificationManager.error(getErrorMessage(error));
    };

    try {
      const payload = {
        dataroomId: dataroom.id,
        folders: formData.folders,
        links: formData.links,
        files,
      };

      const filesystemRepository = container.get<FilesystemRepository>(FilesystemRepository);
      const resultCanRename = await filesystemRepository.canBulkRename(payload);

      if (resultCanRename.success) {
        const resultRename = await filesystemRepository.bulkRename({ ...payload, onFinish, onError });

        if (!resultRename.success) {
          handleError(resultRename);
        }

        return resultRename;
      }

      setIsFetching(false);
      handleError(resultCanRename);

      return resultCanRename;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return null;
    }
  };

  return {
    isFetching,
    bulkRename,
  };
};

export default useBulkRename;
